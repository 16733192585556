/* eslint-disable react-hooks/exhaustive-deps */
import { useReducer, useMemo, useCallback, memo, useEffect } from 'react';
import ClientsContext from './ClientsContext';
import initialClientsState from './initialClientsState';
import userReducer from 'contexts/clients/reducer/clients.reducer';
import * as action from 'contexts/clients/reducer/clients.actions';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import {
  IaddNewExchangeRateProps,
  IClientState,
  IPointOfSaleState,
  IUpdateAliasPointOfSaleProps,
  IupdateExchangeRateProps,
  TClientToUpdateRequest,
  TDataUpgrade,
  TPointsOfSaleToUpdateRequest,
  TUpgradeClientItems,
} from 'interfaces/clients.interface';
import useContextUser from 'hooks/contexts/useContextUser';
import useContextGeneral from 'hooks/contexts/useContextGeneral';
import { ChildrenProps } from 'interfaces/general.interface';

function ClientsProvider(props: ChildrenProps) {
  const [state, dispatch] = useReducer(userReducer, initialClientsState);
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const { company } = useContextUser();
  const { t: translate } = useTranslation();
  const { exchanges, countries, associationStates } = useContextGeneral();

  useEffect(() => {
    (async () => {
      const token = await getAccessTokenSilently();
      if (!token) return;
      return action.getCategoriesAction(dispatch, token);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const token = await getAccessTokenSilently();
      if (
        token &&
        company.id &&
        state.pointsOfSaleCategories.length > 0 &&
        countries.length > 0 &&
        exchanges.length > 0 &&
        associationStates?.connected?.id
      ) {
        return await action.getClientsAction(
          dispatch,
          company.id,
          associationStates,
          state.pointsOfSaleCategories,
          exchanges,
          countries,
          token,
          translate
        );
      }
    })();
  }, [company.id, associationStates, state.pointsOfSaleCategories, exchanges, countries]);

  useEffect(() => {
    if (state.segmentations?.segmentation?.length > 0) return;
    (async () => {
      if (isAuthenticated && company.brand?.id) {
        const token = await getAccessTokenSilently();
        token &&
          action.getSegmentationsAction(dispatch, company.brand?.id, translate, token);
      }
    })();
  }, [company.brand?.id, isAuthenticated, translate]);

  const selectClient = useCallback(
    async (client: IClientState, navigateTo: string, navigate: () => void) => {
      const token = await getAccessTokenSilently();
      token && action.selectClientAction(dispatch, client, navigate, navigateTo, token);
    },
    []
  );

  const selectPointOfSale = useCallback((pointOfSale: IPointOfSaleState) => {
    action.selectPointOfSaleAction(dispatch, pointOfSale);
  }, []);

  const createClient = useCallback(
    async (
      client: IClientState,
      setIsOpenDrawer: any,
      navigate: any,
      resetGoogleAddress: () => void
    ) => {
      const token = await getAccessTokenSilently();
      token &&
        action.createClientAction(
          dispatch,
          client,
          countries,
          setIsOpenDrawer,
          resetGoogleAddress,
          navigate,
          token,
          translate
        );
    },
    [translate, countries]
  );

  const updateClient = useCallback(
    async (
      client: IClientState & { country_id: string; code: string },
      setIsOpenDrawer: any
    ) => {
      const token = await getAccessTokenSilently();
      token &&
        associationStates &&
        action.updateClientAction(
          dispatch,
          state.clientSelected.key,
          state.clientSelected.dataColumns,
          state.pointsOfSaleCategories,
          exchanges,
          client,
          company.brand?.id,
          associationStates,
          countries,
          setIsOpenDrawer,
          token,
          translate
        );
    },
    [
      state.clientSelected.key,
      company.brand?.id,
      state.pointsOfSaleCategories,
      exchanges,
      state.clientSelected.dataColumns,
      associationStates,
      countries,
      translate,
    ]
  );

  const upgradeClientItems = useCallback(
    async ({ values, clientId }: { values: TUpgradeClientItems[]; clientId: string }) => {
      const token = await getAccessTokenSilently();
      return (await action.upgradeClientItemsAction(
        dispatch,
        clientId,
        state,
        values,
        countries,
        token,
        translate
      )) as boolean;
    },
    [countries, state]
  );

  const createPointOfSale = useCallback(
    async (
      pointOfSale: any,
      setIsOpenDrawer: any,
      resetGoogleAddress: () => void,
      form: any,
      showConnected: any
    ) => {
      const token = await getAccessTokenSilently();
      token &&
        associationStates &&
        action.createPointOfSaleAction(
          dispatch,
          state,
          pointOfSale,
          associationStates,
          countries,
          setIsOpenDrawer,
          resetGoogleAddress,
          form,
          showConnected,
          token,
          translate
        );
    },
    [state, associationStates, countries, translate]
  );

  const updatePointOfSale = useCallback(
    async (pointOfSale: IUpdateAliasPointOfSaleProps) => {
      const token = await getAccessTokenSilently();
      return await action.updatePointOfSaleAction({
        dispatch,
        tenantId: company.id,
        clientState: state,
        pointOfSale,
        countries,
        token,
        translate,
      });
    },
    [company.id, state, countries, translate]
  );

  const removePointOfSale = useCallback(
    async (pointOfSale: IPointOfSaleState) => {
      const token = await getAccessTokenSilently();
      if (!token) return false;
      return (await action.removePointOfSaleAction(
        dispatch,
        state,
        pointOfSale,
        token,
        translate
      )) as boolean;
    },
    [state, translate]
  );

  const upgradePointsOfSale = useCallback(
    async (fieldsToUpgrade: TDataUpgrade[], clientId: string) => {
      const token = await getAccessTokenSilently();
      action.upgradePointsOfSaleAction(
        dispatch,
        company.id,
        countries,
        state,
        fieldsToUpgrade,
        clientId,
        token,
        translate
      );
    },
    [company.id, state, countries]
  );

  const shareDataPointOfSale = useCallback(
    async (pointOfSaleId: string, haveToShare: boolean) => {
      const token = await getAccessTokenSilently();
      token &&
        action.shareDataPointOfSaleAction(
          dispatch,
          state,
          pointOfSaleId,
          haveToShare,
          token,
          translate
        );
    },
    [state, translate]
  );

  const searchClients = useCallback(
    (query: string) => {
      action.searchClientsAction(dispatch, state, query);
    },
    [state]
  );

  const searchPointsOfSale = useCallback(
    (query: string) => {
      action.searchPointsOfSaleAction(dispatch, state, query);
    },
    [state]
  );

  const acceptAssociation = useCallback(
    async (
      data: TClientToUpdateRequest,
      status: string,
      setIsOpenDrawer: any,
      setCurrentStep: any
    ) => {
      const token = await getAccessTokenSilently();
      token &&
        associationStates &&
        action.acceptAssociationAction(
          dispatch,
          state.clients,
          state.pointsOfSaleCategories,
          exchanges,
          company.id,
          data,
          status,
          associationStates,
          countries,
          setIsOpenDrawer,
          setCurrentStep,
          token,
          translate
        );
    },
    [
      company.id,
      associationStates,
      state.pointsOfSaleCategories,
      exchanges,
      state.clients,
      countries,
      translate,
    ]
  );

  const rejectAssociation = useCallback(
    async (data: IClientState) => {
      const token = await getAccessTokenSilently();
      token &&
        associationStates &&
        action.rejectAssociationAction(
          dispatch,
          data,
          state.pointsOfSaleCategories,
          exchanges,
          company.id,
          associationStates,
          countries,
          token,
          translate
        );
    },
    [
      company.id,
      associationStates,
      state.pointsOfSaleCategories,
      exchanges,
      countries,
      translate,
    ]
  );

  const associatePointsOfSale = useCallback(
    async (
      data: TPointsOfSaleToUpdateRequest[],
      setPointsOfSaleToUpdate: any,
      setIsOpenDrawer: any,
      setCurrentStep: any
    ) => {
      const token = await getAccessTokenSilently();
      token &&
        associationStates &&
        action.associatePointsOfSaleAction(
          dispatch,
          state,
          company.id,
          data,
          associationStates,
          countries,
          setPointsOfSaleToUpdate,
          setIsOpenDrawer,
          setCurrentStep,
          token,
          translate,
          exchanges
        );
    },
    [company.id, associationStates, state, countries, translate, exchanges]
  );

  const rejectPointOfSale = useCallback(
    async (data: IPointOfSaleState) => {
      const token = await getAccessTokenSilently();
      token &&
        (await action.rejectPointOfSaleAction(
          dispatch,
          data,
          state.clientSelected,
          token,
          translate
        ));
    },
    [state.clientSelected, translate]
  );

  const addNewExchangeRate = useCallback(
    async ({ pointOfSaleId, newExchangeRates }: IaddNewExchangeRateProps) => {
      const token = await getAccessTokenSilently();
      return await action.addNewExchangeRateAction({
        dispatch,
        pointOfSaleId,
        newExchangeRates,
        token,
        translate,
      });
    },
    [translate]
  );

  const updateExchangeRate = useCallback(
    async ({ exchangeRate }: IupdateExchangeRateProps) => {
      const token = await getAccessTokenSilently();
      return await action.updateExchangeRateAction({
        dispatch,
        exchangeRate,
        token,
        translate,
      });
    },
    [translate]
  );

  const memoProvider = useMemo(
    () => ({
      ...state,
      selectClient,
      selectPointOfSale,
      createClient,
      updateClient,
      upgradeClientItems,
      createPointOfSale,
      updatePointOfSale,
      removePointOfSale,
      upgradePointsOfSale,
      shareDataPointOfSale,
      searchClients,
      searchPointsOfSale,
      acceptAssociation,
      rejectAssociation,
      associatePointsOfSale,
      rejectPointOfSale,
      addNewExchangeRate,
      updateExchangeRate,
    }),
    [
      state,
      selectClient,
      selectPointOfSale,
      createClient,
      updateClient,
      upgradeClientItems,
      createPointOfSale,
      updatePointOfSale,
      removePointOfSale,
      upgradePointsOfSale,
      shareDataPointOfSale,
      searchClients,
      searchPointsOfSale,
      acceptAssociation,
      rejectAssociation,
      associatePointsOfSale,
      rejectPointOfSale,
      addNewExchangeRate,
      updateExchangeRate,
    ]
  );

  return (
    <ClientsContext.Provider value={memoProvider}>
      {props.children}
    </ClientsContext.Provider>
  );
}

export default memo(ClientsProvider);
