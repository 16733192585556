import * as apiUser from 'api/endpoints/user/user.api';
import * as apiCompany from 'api/endpoints/company/company.api';
import * as UserTypes from './user.types';
import { notificationErr } from 'views/components/UI/notification';
import { userCompanyError } from 'utils/errorCodes';
import {
  messageError,
  messageLoading,
  messageSuccess,
} from 'views/components/UI/message';
import * as clientKey from 'utils/constants';
import {
  ICompanyUpdateFields,
  IDbUserState,
  TLanguage,
  TSocialMedia,
  TUpdateLanguagePick,
  TUpdateUserPick,
} from 'interfaces/user.interface';
import {
  IGetExchangesAPIResponseItem,
  TLanguageResponseItem,
} from 'api/endpoints/general/general.response.interface';
import {
  IaddSocialMediaAPIResponseItem,
  IupdateTenantDataAPIBody,
  TCompanyAPIResponse,
} from 'api/endpoints/company/company.response.interface';
import { ITranslate } from 'interfaces/general.interface';
import {
  ISAdminTenantState,
  ISAdminUserTenantState,
} from 'api/endpoints/superAdmin/superAdmin.response.interface';
import {
  IUpdateUserAPIBody,
  TloginDbUserAPIResponse,
  TloginDbUserAPIResponseItem,
} from 'api/endpoints/user/user.response.interface';
import { Dispatch } from 'react';

export async function loginUserAction({
  dispatch,
  token,
  emailVerified,
  translate,
  logout,
}: {
  dispatch: Dispatch<any>;
  token: string;
  emailVerified: boolean;
  translate: ITranslate;
  logout: Function;
}): Promise<boolean> {
  let user: TloginDbUserAPIResponseItem | null = null;
  try {
    const userFetch = await apiUser.loginDbUserAPI({ token });
    if (userFetch.response.status === 200) {
      user = userFetch.data;
    }
    return await loginExcep({
      dispatch,
      userFetch,
      logout,
      translate,
      token,
      error: userCompanyError.login_user_001,
    });
  } catch (err) {
    notificationErr(userCompanyError.login_user_002, translate);
    return false;
  } finally {
    if (user && user.enabled === false && emailVerified === true) {
      try {
        const body: IUpdateUserAPIBody = {
          filter: { id: user.id },
          update: { enabled: true },
        };
        const verifyFetch = await apiUser.updateDbUserAPI({ body, token });
        if (verifyFetch.response.status === 200) {
          dispatch({
            type: UserTypes.VERIFY_EMAIL,
            payload: true,
          });
        }
      } catch (error) {
        messageError('Error al verificar el usuario.');
        notificationErr(userCompanyError.login_user_003, translate);
      }
    }
  }
}

export async function updateUserAction({
  dispatch,
  dbUser,
  data,
  token,
  translate,
}: {
  dispatch: Dispatch<any>;
  dbUser: IDbUserState;
  data: TUpdateUserPick;
  token: string;
  translate: ITranslate;
}) {
  const body: IUpdateUserAPIBody = { filter: { id: dbUser.id }, update: data };
  try {
    const fetch = await apiUser.updateDbUserAPI({ body, token });

    if (fetch.response.status === 403) {
      messageError('No tienes permisos.');
      notificationErr(userCompanyError.update_user_002, translate);
      return false;
    }

    if (fetch.response.status === 200) {
      messageSuccess('Usuario actualizado correctamente.');
      dispatch({
        type: UserTypes.UPDATE_USER,
        payload: data,
      });
      return true;
    }
    return false;
  } catch (err) {
    messageError('Error al actualizar el usuario.');
    notificationErr(userCompanyError.update_user_001, translate);
    return false;
  }
}

export async function updateCompanyAction({
  dispatch,
  companyState,
  companyData,
  exchanges,
  token,
  translate,
}: {
  dispatch: Dispatch<any>;
  companyState: TCompanyAPIResponse;
  companyData: ICompanyUpdateFields;
  exchanges: Array<IGetExchangesAPIResponseItem>;
  token: string;
  translate: ITranslate;
}) {
  const newSocialMedia = companyData.new_social_media;
  delete companyData.new_social_media;
  const body: IupdateTenantDataAPIBody = {
    filter: { id: companyState.id },
    update: companyData,
  };
  try {
    const [companyFetch, socialMediaFetch] = await Promise.all([
      apiCompany.updateTenantDataAPI({ body, token }),
      apiCompany.addSocialMediaAPI({
        tenantId: companyState.id,
        items: newSocialMedia as Array<TSocialMedia>,
        token,
      }),
    ]);
    if (
      socialMediaFetch.response.status === 200 &&
      (socialMediaFetch.data as Array<IaddSocialMediaAPIResponseItem>).length > 0
    ) {
      for (const item of socialMediaFetch.data as Array<IaddSocialMediaAPIResponseItem>) {
        const socialMediaItem: TSocialMedia = {
          id: item.id,
          name: item.name,
          url: item.url,
        };
        dispatch({
          type: UserTypes.ADD_SOCIAL_MEDIA,
          payload: socialMediaItem,
        });
      }
    }
    if (companyFetch.response.status === 200) {
      messageSuccess('Datos de la compañia actualizados correctamente.');
      dispatch({
        type: UserTypes.UPDATE_COMPANY,
        payload: {
          companyData,
          exchanges,
        },
      });
      return true;
    }
    if (companyFetch.response.status === 404) {
      messageError('No se ha encontrado al usuario.');
      notificationErr(userCompanyError.update_company_003, translate);
      return false;
    }
    messageError('Error al actualizar los datos de empresa.');
    notificationErr(userCompanyError.update_company_002, translate);
    return false;
  } catch (err) {
    messageError('Error al actualizar los datos de empresa.');
    notificationErr(userCompanyError.update_company_001, translate);
    return false;
  }
}

export async function updateUserAvatarAction({
  dispatch,
  userId,
  image,
  token,
  translate,
}: {
  dispatch: Dispatch<any>;
  userId: string;
  image: File;
  token: string;
  translate: ITranslate;
}) {
  messageLoading('Actualizando imagen...');
  try {
    const fetch = await apiUser.updateUserAvatarAPI({
      userId,
      image,
      token,
    });
    if (fetch.response.status === 200) {
      messageSuccess('Avatar de usuario actualizado correctamente.');
      dispatch({
        type: UserTypes.UPDATE_USER_AVATAR,
        payload: { url: fetch.data.url },
      });
      return true;
    }
    return false;
  } catch (err) {
    messageError('Error al actualizar el avatar de usuario.');
    notificationErr(userCompanyError.update_user_avatar_001, translate);
    return false;
  }
}

export async function updateCompanyLogoAction({
  dispatch,
  tenantId,
  image,
  token,
  translate,
}: {
  dispatch: Dispatch<any>;
  tenantId: string;
  image: File;
  token: string;
  translate: ITranslate;
}) {
  messageLoading('Actualizando imagen...');
  try {
    const fetch = await apiUser.updateCompanyLogoAPI({ tenantId, image, token });
    if (fetch.response.status === 200) {
      messageSuccess('Logo de compañia actualizado correctamente.');
      dispatch({
        type: UserTypes.UPDATE_COMPANY_LOGO,
        payload: { url: fetch.data.url },
      });
      return true;
    }
    return false;
  } catch (err) {
    messageError('Error al actualizar el logo de la compañia.');
    notificationErr(userCompanyError.update_company_logo_001, translate);
    return false;
  }
}

export async function updateLanguageAction({
  dispatch,
  dbUser,
  language,
  token,
  translate,
}: {
  dispatch: Dispatch<any>;
  dbUser: IDbUserState;
  language: TLanguage;
  token: string;
  translate: ITranslate;
}) {
  if (language.id === dbUser.language.id) return false;
  const body: IUpdateUserAPIBody = {
    filter: { id: dbUser.id },
    update: { i18n_lang_id: language.id } as TUpdateLanguagePick,
  };
  try {
    const updateLanguageFetch = await apiUser.updateDbUserAPI({ body, token });
    if (updateLanguageFetch.response.status === 200) {
      dispatch({ type: UserTypes.UPDATE_LANGUAGE, payload: language });
      return true;
    } else {
      notificationErr(userCompanyError.update_language_002, translate);
      return false;
    }
  } catch (err) {
    notificationErr(userCompanyError.update_language_001, translate);
    return false;
  }
}

export async function updateSocialMediaAction({
  dispatch,
  companyState,
  socialMedia,
  token,
  translate,
}: {
  dispatch: Dispatch<any>;
  companyState: TCompanyAPIResponse;
  socialMedia: TSocialMedia;
  token: string;
  translate: ITranslate;
}) {
  const { id, url, name } = socialMedia;
  try {
    const updateFetch = await apiCompany.updateSocialMediaAPI({
      tenant_id: companyState.id,
      id,
      url,
      name,
      token,
    });
    if (updateFetch.response.status === 200 && updateFetch.data[0] === 1) {
      messageSuccess('Red social actualizada correctamente.');
      const findSocialMedia = companyState.tenant_social_media.findIndex(
        (el) => el.id === id
      );
      companyState.tenant_social_media[findSocialMedia].url = url;
      companyState.tenant_social_media[findSocialMedia].name = name;
      dispatch({
        type: UserTypes.SET_SOCIAL_MEDIA,
        payload: companyState.tenant_social_media,
      });
      return true;
    } else {
      messageError('Error al actualizar la red social.');
      notificationErr(userCompanyError.updateSocialMedia_002, translate);
      return false;
    }
  } catch (err) {
    messageError('Error al actualizar la red social.');
    notificationErr(userCompanyError.updateSocialMedia_001, translate);
    return false;
  }
}

export async function removeSocialMediaAction({
  dispatch,
  companyState,
  socialMediaId,
  token,
  translate,
}: {
  dispatch: Dispatch<any>;
  companyState: TCompanyAPIResponse;
  socialMediaId: string;
  token: string;
  translate: ITranslate;
}) {
  try {
    const removeFetch = await apiCompany.removeSocialMediaAPI({ socialMediaId, token });
    if (removeFetch.response.status === 200 && removeFetch.data === 1) {
      const filteredCompany = companyState.tenant_social_media.filter(
        (el) => el.id !== socialMediaId
      );
      messageSuccess('Red social eliminada correctamente.');
      dispatch({
        type: UserTypes.SET_SOCIAL_MEDIA,
        payload: filteredCompany,
      });
      return true;
    } else {
      messageError('Error al eliminar la red social.');
      notificationErr(userCompanyError.removeSocialMedia_002, translate);
      return false;
    }
  } catch (err) {
    notificationErr(userCompanyError.removeSocialMedia_001, translate);
    return false;
  }
}

export async function selectUserAndCompanySuperAdminAction({
  dispatch,
  tenant,
  user,
  languages,
  translate,
  token,
}: {
  dispatch: Dispatch<any>;
  tenant: ISAdminTenantState;
  user: ISAdminUserTenantState;
  languages: TLanguageResponseItem[];
  translate: ITranslate;
  token: string;
}) {
  if (!tenant || !user) return false;
  try {
    const [companyFetch] = await Promise.all([
      apiCompany.getCompanyInfoAPI({
        tenantId: tenant.id,
        countryId: tenant.country.id,
        token,
      }),
    ]);
    if (companyFetch.response.status === 200) {
      dispatch({
        type: UserTypes.LOGIN_COMPANY_USER_SUPER_ADMIN,
        payload: {
          user: user,
          company: companyFetch.data,
          languages,
        },
      });
      return true;
    }
    notificationErr(userCompanyError.selectUserAndCompanySuperAdmin_002, translate);
    return false;
  } catch (error) {
    notificationErr(userCompanyError.selectUserAndCompanySuperAdmin_001, translate);
    return false;
  } finally {
    dispatch({ type: UserTypes.SET_IS_LOADING, payload: false });
  }
}

export async function selectCompanySuperAdminAction({
  dispatch,
  tenant,
  translate,
  token,
}: {
  dispatch: Dispatch<any>;
  tenant: ISAdminTenantState;
  translate: ITranslate;
  token: string;
}) {
  if (!tenant) {
    notificationErr(userCompanyError.selectCompanySuperAdmin_003, translate);
    return false;
  }
  try {
    const [companyFetch] = await Promise.all([
      apiCompany.getCompanyInfoAPI({
        tenantId: tenant.id,
        countryId: tenant.country.id,
        token,
      }),
    ]);
    if (companyFetch.response.status === 200) {
      dispatch({
        type: UserTypes.LOGIN_COMPANY_SUPER_ADMIN,
        payload: {
          company: companyFetch.data,
        },
      });
      return true;
    }
    notificationErr(userCompanyError.selectCompanySuperAdmin_002, translate);
    return false;
  } catch (err) {
    notificationErr(userCompanyError.selectCompanySuperAdmin_001, translate);
    return false;
  } finally {
    dispatch({ type: UserTypes.SET_IS_LOADING, payload: false });
  }
}

///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

const loginExcep = async ({
  dispatch,
  userFetch,
  logout,
  translate,
  token,
  error,
}: {
  dispatch: Dispatch<any>;
  userFetch: TloginDbUserAPIResponse;
  logout: Function;
  translate: ITranslate;
  token: string;
  error: string;
}): Promise<boolean> => {
  if (!userFetch) return false;

  const getUrlToRedirectId = () => {
    if (
      process.env.REACT_APP_NODE_ENV === clientKey.clientLocalKey ||
      !process.env.REACT_APP_NODE_ENV
    ) {
      return clientKey.clientLocal;
    } else if (process.env.REACT_APP_NODE_ENV === clientKey.clientProdKey) {
      return clientKey.clientProd;
    } else if (process.env.REACT_APP_NODE_ENV === clientKey.clientDevKey) {
      return clientKey.clientDev;
    } else if (process.env.REACT_APP_NODE_ENV === clientKey.clientStagingKey) {
      return clientKey.clientStaging;
    }
  };

  if (userFetch?.response?.status === 404 || !userFetch.data) {
    logout();
    return false;
  }

  if (userFetch?.response?.status === 403) {
    window.location.replace(getUrlToRedirectId() as string);
    return false;
  }

  if (userFetch?.response?.status === 200) {
    if (userFetch.data.role === clientKey.superAdminRole) {
      dispatch({
        type: UserTypes.LOGIN_USER,
        payload: {
          user: userFetch.data,
        },
      });
      return true;
    } else {
      try {
        if (!userFetch?.data?.user_tenants) return false;
        const tenantId = userFetch?.data?.user_tenants[0]?.tenant?.tenant_id;
        if (!tenantId) return false;
        const [companyFetch] = await Promise.all([
          apiCompany.getCompanyInfoAPI({
            tenantId: tenantId,
            countryId: userFetch?.data?.user_tenants?.[0].tenant.country_id,
            token,
          }),
        ]);
        dispatch({
          type: UserTypes.LOGIN_USER,
          payload: {
            user: userFetch.data,
            company: companyFetch.response.status === 200 ? companyFetch.data : null,
          },
        });
        return true;
      } catch (error) {
        console.log(error);
      } finally {
        dispatch({ type: UserTypes.SET_IS_LOADING, payload: false });
      }
    }
    return false;
  } else {
    notificationErr(error, translate);
    return false;
  }
};
