import { getBasePath } from '../../utils/config';
import {
  IUpdateUserAPIBody,
  IupdateUserCompanyImageAPIResponse,
  TloginDbUserAPIResponse,
  TUpdateUserApiResponse,
} from 'api/endpoints/user/user.response.interface';

export const loginDbUserAPI = async ({
  token,
}: {
  token: string;
}): Promise<TloginDbUserAPIResponse> => {
  const params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${getBasePath()}/user/login/brand`, params);
  const data = await response.json();
  return { response, data };
};

export const updateDbUserAPI = async ({
  body,
  token,
}: {
  body: IUpdateUserAPIBody;
  token: string;
}): Promise<TUpdateUserApiResponse> => {
  const params = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(`${getBasePath()}/user/update_user`, params);
  const data = await response.json();
  return { response, data };
};

export const updateUserAvatarAPI = async ({
  userId,
  image,
  token,
}: {
  userId: string;
  image: File;
  token: string;
}): Promise<IupdateUserCompanyImageAPIResponse> => {
  const url = `${getBasePath()}/upload/brand_user_profile_image?user_id=${userId}`;
  const formData = new FormData();
  formData.append('image', image);
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  });
  const data = await response.json();
  return { response, data };
};

export const updateCompanyLogoAPI = async ({
  tenantId,
  image,
  token,
}: {
  tenantId: string;
  image: File;
  token: string;
}): Promise<IupdateUserCompanyImageAPIResponse> => {
  const url = `${getBasePath()}/upload/brand_logo?tenant_id=${tenantId}`;
  const formData = new FormData();
  formData.append('image', image);
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  });
  const data = await response.json();
  return { response, data };
};

export const changePasswordAPI = async ({
  auth0Id,
  newPassword,
  token,
}: {
  auth0Id: string;
  newPassword: string;
  token: string;
}): Promise<TloginDbUserAPIResponse> => {
  const params = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      filter: { id: auth0Id },
      update: { password: newPassword },
    }),
  };
  const response = await fetch(`${getBasePath()}/user/update_user_password`, params);
  const data = await response.json();
  return { response, data };
};
