import { notification } from 'antd';
import { ITranslate } from 'interfaces/general.interface';

export const notificationError = ({
  msg,
  description,
  errorCode,
}: {
  msg: string;
  description: string;
  errorCode: string;
}) => {
  return notification.error({
    message: `${msg}: ${errorCode}`,
    description: description,
    placement: 'bottomRight',
    duration: 0,
    style: { backgroundColor: '#212529' },
  });
};

export const notificationErr = (error: any, translate: ITranslate) => {
  return notificationError({
    msg: translate('notification-error_message'),
    description: translate('notification-error_description'),
    errorCode: error,
  });
};
