import {
  createMappingAPI,
  deleteMappingAPI,
  getCatalogReportsByUploadAPI,
  getCatalogReportsBySeasonAPI,
  getMappingKeysAPI,
  getMyMappingsAPI,
  proccessCatalogCSVFileAPI,
  publishCatalogUploadAPI,
  rejectCatalogUploadAPI,
  saveCatalogCSVFileAPI,
  updateSeasonAPI,
} from 'api/endpoints/catalogUploads/catalogUploads.api';
import * as CatalogCsvUploadType from './catalogCsvUpload.types';
import {
  ICreateMapping,
  IInitialCatalogCsvUploadState,
} from 'interfaces/catalogCsvUpload.interface';
import { messageError, messageSuccess } from 'views/components/UI/message';
import { catalogAddCatalogPreview } from 'router/paths';
import { notificationErr } from 'views/components/UI/notification';
import { catalogCSVUpload } from 'utils/errorCodes';
import { IgetCatalogReportsBySeasonAPIResponseItem } from 'api/endpoints/catalogUploads/catalogUploads.response.interface';
import { TCompanyAPIResponse } from 'api/endpoints/company/company.response.interface';
import { ITranslate } from 'interfaces/general.interface';
import { Dispatch } from 'react';

export async function getReportsByUploadAction(
  dispatch: Dispatch<any>,
  brandId: string,
  token: string,
  translate: ITranslate
) {
  dispatch({ type: CatalogCsvUploadType.SET_IS_LOADING_BY_UPLOAD, payload: true });
  try {
    const getCatalogReportsByUpload = await getCatalogReportsByUploadAPI(brandId, token);
    const data = getCatalogReportsByUpload.data;
    if (getCatalogReportsByUpload.response.status === 200 && data.length > 0) {
      dispatch({
        type: CatalogCsvUploadType.SET_REPORTS_BY_UPLOAD,
        payload: data.filter((report) => report.rejected === false),
      });
    }
  } catch (error) {
    dispatch({ type: CatalogCsvUploadType.SET_IS_LOADING_BY_UPLOAD, payload: false });
    return notificationErr(catalogCSVUpload.get_report_by_upload_001, translate);
  } finally {
    dispatch({ type: CatalogCsvUploadType.SET_IS_LOADING_BY_UPLOAD, payload: false });
  }
}

export async function publishUploadAction(
  dispatch: Dispatch<any>,
  uploadId: string,
  brandId: string,
  publish: boolean,
  token: string,
  translate: ITranslate
) {
  dispatch({
    type: CatalogCsvUploadType.SET_IS_LOADING_PUBLISH_UPLOAD,
    payload: { uploadId, isLoading: true },
  });
  try {
    const publishUpload = await publishCatalogUploadAPI(
      uploadId,
      brandId,
      publish,
      token
    );
    if (publishUpload.response.status === 200) {
      dispatch({
        type: CatalogCsvUploadType.SET_PUBLISH_UPLOAD,
        payload: {
          uploadId,
          publish,
        },
      });
      if (publish) {
        return messageSuccess(translate('catalog-csv-upload_publish-upload-success-1'));
      } else {
        return messageSuccess(translate('catalog-csv-upload_publish-upload-success-2'));
      }
    }
  } catch (error) {
    if (publish) {
      messageSuccess(translate('catalog-csv-upload_publish-upload-error-1'));
    } else {
      messageSuccess(translate('catalog-csv-upload_publish-upload-error-2'));
    }
    notificationErr(catalogCSVUpload.publish_upload_001, translate);
    dispatch({
      type: CatalogCsvUploadType.SET_IS_LOADING_PUBLISH_UPLOAD,
      payload: { uploadId: '', isLoading: false },
    });
  } finally {
    dispatch({
      type: CatalogCsvUploadType.SET_IS_LOADING_PUBLISH_UPLOAD,
      payload: { uploadId: '', isLoading: false },
    });
  }
}

export async function rejectUploadAction(
  dispatch: Dispatch<any>,
  uploadId: string,
  brandId: string,
  token: string,
  translate: ITranslate
) {
  dispatch({
    type: CatalogCsvUploadType.SET_IS_LOADING_REJECT_UPLOAD,
    payload: { uploadId, isLoading: true },
  });
  try {
    const rejectUpload = await rejectCatalogUploadAPI(uploadId, brandId, token);
    if (rejectUpload.response.status === 200) {
      // dispatch({
      //   type: CatalogCsvUploadType.SET_REJECT_UPLOAD,
      //   payload: uploadId,
      // });
      return messageSuccess(translate('catalog-csv-upload_reject-upload-success'));
    } else {
      notificationErr(catalogCSVUpload.reject_upload_002, translate);
      return messageError(translate('catalog-csv-upload_reject-upload-error'));
    }
  } catch (error) {
    notificationErr(catalogCSVUpload.reject_upload_001, translate);
    messageError(translate('catalog-csv-upload_reject-upload-error'));
    dispatch({
      type: CatalogCsvUploadType.SET_IS_LOADING_REJECT_UPLOAD,
      payload: { uploadId: '', isLoading: false },
    });
  } finally {
    dispatch({
      type: CatalogCsvUploadType.SET_IS_LOADING_REJECT_UPLOAD,
      payload: { uploadId: '', isLoading: false },
    });
  }
}

export async function getReportsBySeasonAction(
  dispatch: Dispatch<any>,
  brandId: string,
  token: string,
  translate: ITranslate
) {
  dispatch({ type: CatalogCsvUploadType.SET_IS_LOADING_BY_SEASON, payload: true });
  try {
    const [getCatalogReportsBySeasonEnabled, getCatalogReportsBySeasonDisabled] =
      await Promise.all([
        getCatalogReportsBySeasonAPI(brandId, true, token),
        getCatalogReportsBySeasonAPI(brandId, false, token),
      ]);
    if (getCatalogReportsBySeasonEnabled.response.status === 200) {
      dispatch({
        type: CatalogCsvUploadType.SET_REPORTS_BY_SEASON,
        payload: getCatalogReportsBySeasonEnabled.data.concat(
          getCatalogReportsBySeasonDisabled.data
        ),
      });
    }
  } catch (error) {
    notificationErr(catalogCSVUpload.get_reports_by_season_001, translate);
    dispatch({ type: CatalogCsvUploadType.SET_IS_LOADING_BY_SEASON, payload: false });
  } finally {
    dispatch({ type: CatalogCsvUploadType.SET_IS_LOADING_BY_SEASON, payload: false });
  }
}

export async function setStartSeasonDateAction(
  dispatch: Dispatch<any>,
  report: IgetCatalogReportsBySeasonAPIResponseItem,
  date: any,
  token: string,
  translate: ITranslate
) {
  try {
    const body = { id: report.id, start: date };
    const updateSeasonFetch = await updateSeasonAPI(body, token);
    if (updateSeasonFetch.response.status === 200) {
      dispatch({
        type: CatalogCsvUploadType.SET_START_SEASON_DATE,
        payload: {
          id: report.id,
          date,
        },
      });
      return messageSuccess(
        translate('catalog-csv-upload_set-start-season-date-success')
      );
    } else {
      notificationErr(catalogCSVUpload.set_start_season_date_002, translate);
      return messageError(translate('catalog-csv-upload_set-start-season-date-error'));
    }
  } catch (error) {
    notificationErr(catalogCSVUpload.set_start_season_date_001, translate);
    messageError(translate('catalog-csv-upload_set-start-season-date-error'));
  }
}

export async function setEndSeasonDateAction(
  dispatch: Dispatch<any>,
  report: IgetCatalogReportsBySeasonAPIResponseItem,
  date: any,
  token: string,
  translate: ITranslate
) {
  try {
    const body = { id: report.id, end: date };
    const updateSeasonFetch = await updateSeasonAPI(body, token);
    if (updateSeasonFetch.response.status === 200) {
      dispatch({
        type: CatalogCsvUploadType.SET_END_SEASON_DATE,
        payload: {
          id: report.id,
          date,
        },
      });
      return messageSuccess(translate('catalog-csv-upload_set-end-season-date-success'));
    } else {
      notificationErr(catalogCSVUpload.set_end_season_date_002, translate);
      return messageError(translate('catalog-csv-upload_set-end-season-date-error'));
    }
  } catch (error) {
    notificationErr(catalogCSVUpload.set_end_season_date_001, translate);
    messageError(translate('catalog-csv-upload_set-end-season-date-error'));
  }
}

export async function enableSeasonAction(
  dispatch: Dispatch<any>,
  report: IgetCatalogReportsBySeasonAPIResponseItem,
  enable: boolean,
  token: string,
  translate: ITranslate
) {
  dispatch({
    type: CatalogCsvUploadType.SET_IS_LOADING_ENABLE_SEASON,
    payload: { seasonId: report.id, isLoading: true },
  });
  try {
    const body = { id: report.id, enabled: enable };
    const updateSeasonFetch = await updateSeasonAPI(body, token);
    if (updateSeasonFetch.response.status === 200) {
      dispatch({
        type: CatalogCsvUploadType.SET_PUBLISH_SEASON,
        payload: { id: report.id, enabled: enable },
      });
      setTimeout(() => {
        if (enable === true) {
          return messageSuccess(translate('catalog-csv-upload_enable-season-success-1'));
        } else {
          return messageSuccess(translate('catalog-csv-upload_enable-season-success-2'));
        }
      }, 1000);
    } else {
      if (enable === true) {
        messageSuccess(translate('catalog-csv-upload_enable-season-error-1'));
      } else {
        messageSuccess(translate('catalog-csv-upload_enable-season-error-2'));
      }
      return notificationErr(catalogCSVUpload.enable_season_002, translate);
    }
  } catch (error) {
    notificationErr(catalogCSVUpload.enable_season_001, translate);
    if (enable === true) {
      messageSuccess(translate('catalog-csv-upload_enable-season-error-1'));
    } else {
      messageSuccess(translate('catalog-csv-upload_enable-season-error-2'));
    }
  } finally {
    setTimeout(() => {
      dispatch({
        type: CatalogCsvUploadType.SET_IS_LOADING_ENABLE_SEASON,
        payload: { seasonId: '', isLoading: false },
      });
    }, 1000);
  }
}

export async function setCSVSelectedAction(
  dispatch: Dispatch<any>,
  data: Papa.ParseResult<unknown>,
  file: File,
  navigate: Function | undefined
) {
  dispatch({
    type: CatalogCsvUploadType.SET_CSV_SELECTED,
    payload: { papa: data, file },
  });
  navigate && navigate(catalogAddCatalogPreview);
  dispatch({ type: CatalogCsvUploadType.SET_IS_LOADING_CSV_SELECTED, payload: false });
}

export async function getMappingsAction(
  dispatch: Dispatch<any>,
  brandId: string,
  token: string,
  translate: ITranslate
) {
  dispatch({ type: CatalogCsvUploadType.SET_IS_LOADING_CSV_MAPPINGS, payload: true });
  try {
    const [getMappingKeys, getMyMappings] = await Promise.all([
      getMappingKeysAPI(token),
      getMyMappingsAPI(brandId, token),
    ]);
    if (getMappingKeys.response.status === 200) {
      dispatch({
        type: CatalogCsvUploadType.SET_CSV_MAPPING_KEYS,
        payload: getMappingKeys.data,
      });
    } else {
      notificationErr(catalogCSVUpload.get_mappings_002, translate);
      messageError(translate('catalog-csv-upload_get-mappings-error-1'));
    }
    if (getMyMappings.response.status === 200) {
      dispatch({
        type: CatalogCsvUploadType.SET_CSV_MAPPINGS,
        payload: getMyMappings.data,
      });
    }
  } catch (error) {
    notificationErr(catalogCSVUpload.get_mappings_001, translate);
    messageError(translate('catalog-csv-upload_get-mappings-error-2'));
    dispatch({ type: CatalogCsvUploadType.SET_IS_LOADING_CSV_MAPPINGS, payload: false });
  } finally {
    dispatch({ type: CatalogCsvUploadType.SET_IS_LOADING_CSV_MAPPINGS, payload: false });
  }
}

export async function deleteMappingAction(
  dispatch: Dispatch<any>,
  mappingId: string,
  token: string,
  translate: ITranslate
) {
  dispatch({ type: CatalogCsvUploadType.SET_IS_LOADING_CSV_MAPPINGS, payload: true });
  try {
    const deleteMapping = await deleteMappingAPI(mappingId, token);
    if (deleteMapping.response.status === 200) {
      dispatch({
        type: CatalogCsvUploadType.DELETE_MAPPING,
        payload: mappingId,
      });
      return messageSuccess(translate('catalog-csv-upload_delete-mapping-success'));
    } else {
      return notificationErr(catalogCSVUpload.delete_mapping_002, translate);
    }
  } catch (error) {
    notificationErr(catalogCSVUpload.delete_mapping_001, translate);
    messageError(translate('catalog-csv-upload_delete-mapping-error'));
    dispatch({ type: CatalogCsvUploadType.SET_IS_LOADING_CSV_MAPPINGS, payload: false });
  } finally {
    dispatch({ type: CatalogCsvUploadType.SET_IS_LOADING_CSV_MAPPINGS, payload: false });
  }
}

export async function createMappingAction(
  dispatch: Dispatch<any>,
  body: ICreateMapping,
  token: string,
  translate: ITranslate
) {
  dispatch({ type: CatalogCsvUploadType.SET_IS_LOADING_CSV_MAPPINGS, payload: true });
  try {
    const createMapping = await createMappingAPI(body, token);
    if (createMapping.response.status === 200) {
      dispatch({
        type: CatalogCsvUploadType.CREATE_MAPPING,
        payload: createMapping.data,
      });
      return messageSuccess(translate('catalog-csv-upload_create-mapping-success'));
    } else {
      notificationErr(catalogCSVUpload.create_mapping_002, translate);
    }
  } catch (error) {
    notificationErr(catalogCSVUpload.create_mapping_001, translate);
    messageError(translate('catalog-csv-upload_create-mapping-error'));
    dispatch({ type: CatalogCsvUploadType.SET_IS_LOADING_CSV_MAPPINGS, payload: false });
  } finally {
    dispatch({ type: CatalogCsvUploadType.SET_IS_LOADING_CSV_MAPPINGS, payload: false });
  }
}

export async function saveCatalogCSVFileAction(
  dispatch: Dispatch<any>,
  catalogCsvUploadtate: IInitialCatalogCsvUploadState,
  company: TCompanyAPIResponse,
  token: string,
  translate: ITranslate
) {
  dispatch({ type: CatalogCsvUploadType.SET_IS_LOADING_SAVE_CSV, payload: true });
  try {
    const saveFileFetch = await saveCatalogCSVFileAPI({
      brandId: company.brand.id,
      original_content_type: catalogCsvUploadtate.csvSelected.file.type,
      original_name: catalogCsvUploadtate.csvSelected.file.name,
      encoding: (catalogCsvUploadtate.csvSelected.papa.meta as any).encoding,
      token,
    });
    if (saveFileFetch.response.status !== 200) return false;
    try {
      const uploadFile = await fetch(saveFileFetch.data.items[0].upload_url, {
        method: 'PUT',
        body: catalogCsvUploadtate.csvSelected.file,
        headers: {
          'Content-Type': saveFileFetch.data.items[0].content_type,
          'Content-Encoding': saveFileFetch.data.items[0].encoding,
        },
      });
      if (uploadFile.status !== 200) return false;
      const proccessFileFetch = await proccessCatalogCSVFileAPI({
        brandId: saveFileFetch.data.brand_id,
        key: saveFileFetch.data.items[0].key,
        token,
      });
      if (proccessFileFetch.response.status !== 200) return false;
      return true;
    } catch (error) {
      console.log(error);
    }
  } catch (error) {
    console.log(error);
  } finally {
    dispatch({ type: CatalogCsvUploadType.SET_IS_LOADING_SAVE_CSV, payload: false });
  }
}
