import * as MetaTypes from '../../meta.types';
import { IInitialMetaState, IMap, IMapFieldMap } from 'interfaces/meta.interface';

export function handleMapFields(
  state: IInitialMetaState,
  action: any
): IInitialMetaState {
  const { type, payload } = action;

  switch (type) {
    case MetaTypes.GET_MAP_FIELDS: {
      const getMapField: IInitialMetaState = {
        ...state,
        mapFields: payload,
      };
      return getMapField;
    }

    case MetaTypes.CREATE_MAP_FIELD: {
      const createMapField: IInitialMetaState = {
        ...state,
        mapFields: [payload, ...state.mapFields],
      };
      return createMapField;
    }

    case MetaTypes.UPDATE_MAP_FIELD: {
      const updateMapField: IInitialMetaState = {
        ...state,
        mapFields: state.mapFields.map((field) =>
          field.id === payload.id ? { ...field, ...payload.data } : field
        ),
        mapSelected: {
          ...state.mapSelected,
          map_field_maps: state.mapSelected?.map_field_maps.map((field) =>
            field.map_field_id === payload.id
              ? {
                  ...field,
                  map_field: {
                    ...field.map_field,
                    ...payload.data,
                  },
                }
              : field
          ) as IMapFieldMap[],
        } as IMap,
      };
      return updateMapField;
    }

    case MetaTypes.IS_LOADING_UPDATE_MAP_FIELD: {
      const isLoadingUpdateMapField: IInitialMetaState = {
        ...state,
        isLoadingUpdateMapField: payload,
      };
      return isLoadingUpdateMapField;
    }

    case MetaTypes.DELETE_MAP_FIELD: {
      const deleteMapField: IInitialMetaState = {
        ...state,
        mapFields: state.mapFields.filter((field) => field.id !== payload),
      };
      return deleteMapField;
    }

    case MetaTypes.DELETE_MAP_FIELDS: {
      const deleteMapFields: IInitialMetaState = {
        ...state,
        mapFields: state.mapFields.filter((field) => !payload.includes(field.id)),
      };
      return deleteMapFields;
    }

    case MetaTypes.IS_LOADING_DELETE_MAP_FIELDS: {
      const isLoadingDeleteMapFields: IInitialMetaState = {
        ...state,
        isLoadingDeleteMapFields: payload,
      };
      return isLoadingDeleteMapFields;
    }

    case MetaTypes.ADD_FIELD_TO_MAP: {
      const addFieldToMap: IInitialMetaState = {
        ...state,
        mappings: state.mappings.map((map) => {
          if (map.id === payload.mapId) {
            return {
              ...map,
              map_field_maps: [...map.map_field_maps, payload.mapField],
            };
          }
          return map;
        }),
      };
      return addFieldToMap;
    }

    case MetaTypes.ADD_FIELDS_TO_MAP: {
      const addFieldsToMap: IInitialMetaState = {
        ...state,
        mappings: state.mappings.map((map) => {
          if (map.id === payload.mapId) {
            return {
              ...map,
              map_field_maps: payload.mapFields,
            };
          }
          return map;
        }),
      };
      return addFieldsToMap;
    }

    case MetaTypes.REMOVE_FIELD_FROM_MAP: {
      const removeFieldFromMap: IInitialMetaState = {
        ...state,
        mappings: state.mappings.map((map) => {
          if (map.id === payload.mapId) {
            return {
              ...map,
              map_field_maps: map.map_field_maps.filter(
                (field) => field.map_field_id !== payload.mapFieldId
              ),
            };
          }
          return map;
        }),
        mapSelected: {
          ...state.mapSelected,
          map_field_maps: state.mapSelected?.map_field_maps.filter(
            (field) => field.map_field_id !== payload.mapFieldId
          ) as IMapFieldMap[],
        } as IMap,
      };
      return removeFieldFromMap;
    }

    case MetaTypes.REMOVE_FIELDS_FROM_MAP: {
      const removeFieldsFromMap: IInitialMetaState = {
        ...state,
        mappings: state.mappings.map((map) => {
          if (map.id === payload.mapId) {
            return {
              ...map,
              map_field_maps: map.map_field_maps.filter(
                (field) => !payload.mapFieldsIds.includes(field.map_field_id)
              ),
            };
          }
          return map;
        }),
        mapSelected: {
          ...state.mapSelected,
          map_field_maps: state.mapSelected?.map_field_maps.filter(
            (field) => !payload.mapFieldsIds.includes(field.map_field_id)
          ) as IMapFieldMap[],
        } as IMap,
      };
      return removeFieldsFromMap;
    }

    case MetaTypes.IS_LOADING_GET_MAP_FIELDS: {
      const isLoadingGetMapFields: IInitialMetaState = {
        ...state,
        isLoadingMapFields: payload,
      };
      return isLoadingGetMapFields;
    }

    case MetaTypes.IS_LOADING_CREATE_MAP_FIELD: {
      const isLoadingCreateMapField: IInitialMetaState = {
        ...state,
        isLoadingCreateMapField: payload,
      };
      return isLoadingCreateMapField;
    }

    case MetaTypes.IS_LOADING_DELETE_MAP_FIELD: {
      const isLoadingDeleteMapField: IInitialMetaState = {
        ...state,
        isLoadingDeleteMapField: {
          loading: payload.loading,
          mapFieldId: payload.mapFieldId,
        },
      };
      return isLoadingDeleteMapField;
    }

    // Map fields templates //////////////////////////////////////////
    case MetaTypes.GET_MAP_FIELDS_TEMPLATES: {
      const getMapFieldsTemplates: IInitialMetaState = {
        ...state,
        mapFieldsTemplates: payload,
      };
      return getMapFieldsTemplates;
    }

    case MetaTypes.IS_LOADING_GET_MAP_FIELDS_TEMPLATES: {
      const isLoadingGetMapFieldsTemplates: IInitialMetaState = {
        ...state,
        isLoadingMapFieldsTemplates: payload,
      };
      return isLoadingGetMapFieldsTemplates;
    }

    case MetaTypes.DELETE_MAP_FIELD_TEMPLATE: {
      const deleteMapFieldTemplate: IInitialMetaState = {
        ...state,
        mapFieldsTemplates: state.mapFieldsTemplates.filter(
          (item) => item.templateName !== payload
        ),
      };
      return deleteMapFieldTemplate;
    }

    case MetaTypes.IS_LOADING_DELETE_MAP_FIELD_TEMPLATE: {
      const isLoadingDeleteMapFieldTemplate: IInitialMetaState = {
        ...state,
        isLoadingDeleteMapFieldTemplate: {
          loading: payload.loading,
          templateName: payload.templateName,
        },
      };
      return isLoadingDeleteMapFieldTemplate;
    }

    default:
      return state;
  }
}
