import {
  createMultimediaAPI,
  findMultimediaAPI,
  removeMultimediaAPI,
  TUpdateMultimediaBody,
  updateMultimediaAPI,
} from 'api/endpoints/multimedia/multimedia.api';
import * as Types from './multimedia.types';
import {
  messageError,
  messageLoading,
  messageSuccess,
} from 'views/components/UI/message';
import { notificationErr } from 'views/components/UI/notification';
import { multimediaError } from 'utils/errorCodes';
import {
  CreateMultimediaBody,
  PostMultimediaResponseState,
  MultimediaTypes,
  TMediaState,
  IInitialMultimediaState,
} from 'interfaces/multimedia.interface';
import { videoType } from 'views/pages/main/multimediaPage/components/constants';
import { TProductAttributeItem, TProductAttributes } from 'interfaces/catalog.interface';
import {
  IfindMultimediaAPIItemResponse,
  IProductItemState,
  IReferenceItemState,
} from 'api/endpoints/multimedia/multimedia.response.interface';
import { TCompanyAPIResponse } from 'api/endpoints/company/company.response.interface';
import { ITranslate } from 'interfaces/general.interface';
import { Dispatch } from 'react';

export async function getMediaAction(
  dispatch: Dispatch<any>,
  multimediaState: TMediaState,
  companyBrandId: string,
  type: MultimediaTypes,
  signal: AbortSignal | undefined,
  translate: ITranslate,
  token: string
) {
  dispatch({
    type: Types.SET_IS_LOADING_MEDIA,
    payload: { type: type, isLoading: true },
  });
  try {
    multimediaState.bodyMultimediaFetch.options.brand_id = [companyBrandId];
    const mediaFetch = await findMultimediaAPI(
      multimediaState.bodyMultimediaFetch,
      signal,
      token
    );
    if (mediaFetch.response.status === 200) {
      return dispatch({
        type: Types.GET_MULTIMEDIA,
        payload: { type: type, items: mediaFetch.data },
      });
    }
  } catch (err: any) {
    if (err.code !== 20) {
      return notificationErr(multimediaError.get_multimedia_001, translate);
    }
  } finally {
    dispatch({
      type: Types.SET_IS_LOADING_MEDIA,
      payload: { type: type, isLoading: false },
    });
  }
}

export async function getMoreMediaAction(
  dispatch: Dispatch<any>,
  multimediaState: TMediaState,
  companyBrandId: string,
  type: MultimediaTypes,
  signal: AbortSignal | undefined,
  translate: ITranslate,
  token: string
) {
  dispatch({
    type: Types.SET_IS_LOADING_MORE_MEDIA,
    payload: { type: type, isLoading: true },
  });
  try {
    multimediaState.bodyMultimediaFetch.options.brand_id = [companyBrandId];
    const mediaFetch = await findMultimediaAPI(
      multimediaState.bodyMultimediaFetch,
      signal,
      token
    );
    if (mediaFetch.response.status === 200) {
      return dispatch({
        type: Types.GET_MULTIMEDIA,
        payload: { type: type, items: mediaFetch.data },
      });
    }
  } catch (err: any) {
    if (err.code !== 20) {
      return notificationErr(multimediaError.get_more_multimedia_001, translate);
    }
  } finally {
    dispatch({
      type: Types.SET_IS_LOADING_MORE_MEDIA,
      payload: { type: type, isLoading: false },
    });
  }
}

export async function createMultimediaAction(
  dispatch: Dispatch<any>,
  company: TCompanyAPIResponse,
  type: MultimediaTypes,
  body: CreateMultimediaBody,
  media: any,
  videoThumbnailMedia: any,
  setCurrentStep: any,
  productAttributes: TProductAttributes,
  products: IProductItemState[],
  translate: ITranslate,
  token: string
) {
  dispatch({ type: Types.SET_IS_LOADING_CREATE_MULTIMEDIA, payload: true });
  try {
    const mediaFetch = await createMultimediaAPI(body, token);
    const mediaFetchData = mediaFetch.data as PostMultimediaResponseState;
    if (mediaFetch.response.status === 200) {
      const { upload_url } = mediaFetchData;
      const mediaBlob = await (media.originFileObj as File).arrayBuffer();
      try {
        const uploadMedia = await fetch(upload_url, {
          method: 'PUT',
          body: new Blob([new Uint8Array(mediaBlob)], { type: media.originFileObj.type }),
        });
        if (uploadMedia.status !== 200) {
          notificationErr(multimediaError.create_multimedia_004, translate);
          messageError('Error al subir el archivo multimedia.');
          return false;
        }
        if (type === videoType && videoThumbnailMedia) {
          const { thumbnail_url } = mediaFetchData;
          const thumbnailBlob = await (
            videoThumbnailMedia.originFileObj ?? (videoThumbnailMedia as File)
          ).arrayBuffer();
          const uploadThumbnail = await fetch(thumbnail_url as string, {
            method: 'PUT',
            body: new Blob([new Uint8Array(thumbnailBlob)], {
              type: videoThumbnailMedia?.originFileObj?.type ?? videoThumbnailMedia.type,
            }),
          });
          if (uploadThumbnail.status !== 200) {
            notificationErr(multimediaError.create_multimedia_003, translate);
            messageError('Error al subir el archivo multimedia.');
            return false;
          }
          await insertMultimedia({
            dispatch,
            data: mediaFetchData,
            type,
            company,
            body,
            productAttributes,
            products,
          });
          messageSuccess('Multimedia creado correctamente.');
          setCurrentStep(2);
          return true;
        }
        await insertMultimedia({
          dispatch,
          data: mediaFetchData,
          type,
          company,
          body,
          productAttributes,
          products,
        });
        messageSuccess('Multimedia creado correctamente.');
        setCurrentStep(2);
        return true;
      } catch (error) {
        notificationErr(multimediaError.create_multimedia_002, translate);
        messageError('Error al subir el archivo multimedia.');
        return false;
      }
    }
  } catch (error) {
    notificationErr(multimediaError.create_multimedia_001, translate);
    messageError('Error del servidero al subir multimedia.');
    return false;
  } finally {
    dispatch({ type: Types.SET_IS_LOADING_CREATE_MULTIMEDIA, payload: false });
  }
}

async function insertMultimedia({
  dispatch,
  data,
  type,
  company,
  body,
  productAttributes,
  products,
}: {
  dispatch: Dispatch<any>;
  data: PostMultimediaResponseState;
  type: MultimediaTypes;
  company: TCompanyAPIResponse;
  body: CreateMultimediaBody;
  productAttributes: TProductAttributes;
  products: IProductItemState[];
}) {
  const divisions: (TProductAttributeItem | undefined)[] =
    body?.multimedia_division
      ?.map((item) => productAttributes.division.find((division) => division.id === item))
      .filter(Boolean) ?? [];
  const seasons: (TProductAttributeItem | undefined)[] =
    body?.multimedia_season
      ?.map((item) => productAttributes.season.find((season) => season.id === item))
      .filter(Boolean) ?? [];
  const genders: (TProductAttributeItem | undefined)[] =
    body?.multimedia_gender
      ?.map((item) => productAttributes.gender.find((gender) => gender.id === item))
      .filter(Boolean) ?? [];

  const newMultimedia = {
    id: data.multimedia.id,
    name: data.multimedia.name,
    title: data.multimedia.title,
    description: data.multimedia.description,
    file_type: data.multimedia.file_type,
    multimedia_type: data.multimedia.multimedia_type,
    url: data.multimedia.url,
    enable_download: data.multimedia.enable_download,
    start: data.multimedia.start,
    end: data.multimedia.end,
    brand: {
      id: data.multimedia.brand_id,
      name: company.brand.name,
      logo: company.brand.logo,
    },
    tag: body.multimedia_tags,
    division: divisions,
    season: seasons,
    gender: genders,
    // country: Country[],
    thumbnail: data.thumbnail,
    product: products,
  };
  dispatch({
    type: Types.CREATE_MULTIMEDIA,
    payload: {
      type: type,
      item: newMultimedia,
    },
  });
}

export async function updateMultimediaAction(
  dispatch: Dispatch<any>,
  multimedia: IInitialMultimediaState,
  productAttributes: TProductAttributes,
  body: TUpdateMultimediaBody,
  type: MultimediaTypes,
  translate: ITranslate,
  token: string
) {
  try {
    const mediaFetch = await updateMultimediaAPI(body, token);
    if (mediaFetch.response.status === 200) {
      const findMultimedia = multimedia[type].items.find((item) => item.id === body.id);
      if (findMultimedia) {
        const copyOfMultimediaItem: IfindMultimediaAPIItemResponse = JSON.parse(
          JSON.stringify(findMultimedia)
        );
        copyOfMultimediaItem.title = body.multimedia.title;
        copyOfMultimediaItem.description = body.multimedia.description;
        copyOfMultimediaItem.enable_download = body.multimedia.enable_download;
        copyOfMultimediaItem.start = body.multimedia.start as string;
        copyOfMultimediaItem.end = body.multimedia.end as string;
        copyOfMultimediaItem.tag = body.tags;
        copyOfMultimediaItem.division = body.division_id
          .map((division) => {
            const divisionItem = productAttributes.division.find(
              (item) => item.id === division
            );
            return divisionItem;
          })
          .filter((item): item is IReferenceItemState => Boolean(item));
        copyOfMultimediaItem.season = body.season_ids
          .map((season) => {
            const seasonItem = productAttributes.season.find(
              (item) => item.id === season
            );
            return seasonItem;
          })
          .filter((item): item is IReferenceItemState => Boolean(item));
        copyOfMultimediaItem.gender = body.gender_id
          .map((gender) => {
            const genderItem = productAttributes.gender.find(
              (item) => item.id === gender
            );
            return genderItem;
          })
          .filter((item): item is IReferenceItemState => Boolean(item));
        copyOfMultimediaItem.product = body.product_ids
          .map((product) => {
            const productItem = copyOfMultimediaItem.product.find(
              (item) => item.id === product
            );
            return productItem;
          })
          .filter((item): item is IProductItemState => Boolean(item));

        dispatch({
          type: Types.UPDATE_MULTIMEDIA,
          payload: {
            type: type,
            item: copyOfMultimediaItem,
          },
        });
      } else {
        const findSearchedMultimedia = multimedia[type].search.media.find(
          (item) => item.id === body.id
        );
        if (findSearchedMultimedia) {
          const copyOfSearchedMultimediaItem: IfindMultimediaAPIItemResponse = JSON.parse(
            JSON.stringify(findSearchedMultimedia)
          );
          copyOfSearchedMultimediaItem.title = body.multimedia.title;
          copyOfSearchedMultimediaItem.description = body.multimedia.description;
          copyOfSearchedMultimediaItem.enable_download = body.multimedia.enable_download;
          copyOfSearchedMultimediaItem.start = body.multimedia.start as string;
          copyOfSearchedMultimediaItem.end = body.multimedia.end as string;
          copyOfSearchedMultimediaItem.tag = body.tags;
          copyOfSearchedMultimediaItem.division = body.division_id
            .map((division) => {
              const divisionItem = productAttributes.division.find(
                (item) => item.id === division
              );
              return divisionItem;
            })
            .filter((item): item is IReferenceItemState => Boolean(item));
          copyOfSearchedMultimediaItem.season = body.season_ids
            .map((season) => {
              const seasonItem = productAttributes.season.find(
                (item) => item.id === season
              );
              return seasonItem;
            })
            .filter((item): item is IReferenceItemState => Boolean(item));
          copyOfSearchedMultimediaItem.gender = body.gender_id
            .map((gender) => {
              const genderItem = productAttributes.gender.find(
                (item) => item.id === gender
              );
              return genderItem;
            })
            .filter((item): item is IReferenceItemState => Boolean(item));
          copyOfSearchedMultimediaItem.product = body.product_ids
            .map((product) => {
              const productItem = copyOfSearchedMultimediaItem.product.find(
                (item) => item.id === product
              );
              return productItem;
            })
            .filter((item): item is IProductItemState => Boolean(item));

          dispatch({
            type: Types.UPDATE_MULTIMEDIA,
            payload: {
              type: type,
              item: copyOfSearchedMultimediaItem,
            },
          });
        }
      }
      messageSuccess('Archivo multimedia actualizado correctamente.');
      return true;
    }
    messageError('Error al actualizar el archivo multimedia.');
    notificationErr(multimediaError.update_multimedia_002, translate);
    return false;
  } catch (err) {
    messageError('Error al actualizar el archivo multimedia.');
    notificationErr(multimediaError.update_multimedia_001, translate);
    return false;
  }
}

export async function removeMultimediaAction(
  dispatch: Dispatch<any>,
  mediaId: string,
  multimediaType: MultimediaTypes,
  translate: ITranslate,
  token: string
) {
  try {
    messageLoading('Eliminando archivo...');
    const mediaFetch = await removeMultimediaAPI(mediaId, token);
    if (mediaFetch.response.status === 200) {
      messageSuccess('Archivo eliminado correctamente.');
      dispatch({
        type: Types.REMOVE_MULTIMEDIA,
        payload: {
          id: mediaId,
          type: multimediaType,
        },
      });
      return true;
    }
    messageError('Error al eliminar el archivo multimedia.');
    notificationErr(multimediaError.remove_multimedia_002, translate);
    return false;
  } catch (err) {
    messageError('Error al eliminar el archivo multimedia.');
    notificationErr(multimediaError.remove_multimedia_001, translate);
    return false;
  }
}

export async function searchMediaAction(
  dispatch: Dispatch<any>,
  multimediaState: TMediaState,
  query: string,
  type: MultimediaTypes,
  signal: AbortSignal | undefined,
  translate: ITranslate,
  token: string
) {
  dispatch({
    type: Types.SET_IS_LOADING_MEDIA,
    payload: { type: type, isLoading: true },
  });

  if (query.length <= 3) {
    dispatch({
      type: Types.SEARCH_MEDIA,
      payload: {
        type: type,
        media: multimediaState.items,
        query: query,
      },
    });
    return dispatch({
      type: Types.SET_IS_LOADING_MEDIA,
      payload: { type: type, isLoading: false },
    });
  }

  try {
    multimediaState.bodyMultimediaFetch.options.search = [`%${query}%`];
    multimediaState.bodyMultimediaFetch.options.index = 0;
    const mediaFetch = await findMultimediaAPI(
      multimediaState.bodyMultimediaFetch,
      signal,
      token
    );
    if (mediaFetch.response.status === 200) {
      return dispatch({
        type: Types.SEARCH_MEDIA,
        payload: {
          type: type,
          media: mediaFetch.data,
          query: query,
        },
      });
    }
  } catch (err: any) {
    if (err.code !== 20) {
      return notificationErr(multimediaError.search_multimedia_001, translate);
    }
  } finally {
    dispatch({
      type: Types.SET_IS_LOADING_MEDIA,
      payload: { type: type, isLoading: false },
    });
  }
}
