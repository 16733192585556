import * as api from 'api/endpoints/mainContacts/mainContacts.api';
import * as MainContactsTypes from './mainContacts.types';
import { mainContactError } from 'utils/errorCodes';
import { notificationErr } from 'views/components/UI/notification';
import { messageError, messageSuccess } from 'views/components/UI/message';
import { IMainContactItemState } from '../initialMainContactsState';
import { ITranslate } from 'interfaces/general.interface';
import { Dispatch } from 'react';
import { IcreateMainContactAPIResponseBody } from 'api/endpoints/mainContacts/mainContacts.response.interface';

export async function getMainContactsAction(
  dispatch: Dispatch<any>,
  tenantId: string,
  token: string,
  translate: ITranslate
) {
  dispatch({ type: MainContactsTypes.SET_IS_LOADING_CONTACTS, payload: true });
  try {
    const contactsFetch = await api.getMainContactsAPI(tenantId, token);
    let orderedContacts: IMainContactItemState[] = [];
    contactsFetch.data.company_tenant_contacts.forEach((item) => {
      const obj = {
        key: item.contact.id,
        name: item.contact.name,
        lastname: item.contact.surname,
        email: item.contact.email,
        phoneNumberPrefix: item.contact.telephone_prefix,
        phoneNumberSufix: item.contact.telephone_sufix,
        department: item.contact.sector,
      };
      orderedContacts.push(obj);
    });
    return dispatch({
      type: MainContactsTypes.GET_MAIN_CONTACTS,
      payload: orderedContacts,
    });
  } catch (err) {
    messageError('Error al obtener los contactos principales.');
    notificationErr(mainContactError.get_contacts_001, translate);
  } finally {
    dispatch({ type: MainContactsTypes.SET_IS_LOADING_CONTACTS, payload: false });
  }
}

export async function selectContactAction(
  dispatch: Dispatch<any>,
  contact: IMainContactItemState,
  translate: ITranslate
) {
  try {
    return dispatch({
      type: MainContactsTypes.SELECT_CONTACT,
      payload: contact,
    });
  } catch (err) {
    messageError('Error al seleccionar el contacto.');
    notificationErr(mainContactError.select_contact_001, translate);
  }
}

export async function createContactAction(
  dispatch: Dispatch<any>,
  tenantId: string,
  contact: any,
  token: string,
  setIsOpenDrawer: (isOpen: boolean) => void,
  translate: ITranslate
) {
  try {
    const body: IcreateMainContactAPIResponseBody = {
      email: contact.email,
      name: contact.name,
      surname: contact.surname,
      tel_prefix: contact.telephone_prefix,
      tel_sufix: contact.telephone_sufix,
      sector: contact.department,
    };
    const createFetch = await api.createMainContactAPI({
      tenantId,
      contact: body,
      token,
    });
    if (createFetch.response.status === 200 || createFetch.response.status === 201) {
      const { data } = createFetch;
      const newContact = {
        key: data.id,
        name: data.name,
        lastname: data.surname,
        email: data.email,
        phoneNumberPrefix: data.tel_prefix,
        phoneNumberSufix: data.tel_sufix,
        department: data.sector,
      };
      dispatch({
        type: MainContactsTypes.CREATE_CONTACT,
        payload: newContact,
      });
      messageSuccess('Contacto creado correctamente.');
      return setIsOpenDrawer(false);
    } else {
      messageError('Error al crear el contacto.');
      notificationErr(mainContactError.create_contact_002, translate);
    }
  } catch (err) {
    messageError('Error al crear el contacto.');
    notificationErr(mainContactError.create_contact_001, translate);
  }
}

export async function updateContactAction(
  dispatch: Dispatch<any>,
  contacts: any,
  newContact: any,
  token: string,
  setIsOpenDrawer: (isOpen: boolean) => void,
  translate: ITranslate
) {
  try {
    const contactId = contacts.contactSelected.key;
    const body = {
      filter: { id: contactId },
      update: {
        email: newContact.email,
        name: newContact.name,
        surname: newContact.surname,
        tel_prefix: newContact.telephone_prefix,
        tel_sufix: newContact.telephone_sufix,
        sector: newContact.department,
      },
    };
    const updateFetch = await api.updateMainContactAPI(body, token);
    if (updateFetch[0] === 1) {
      const findContact = contacts.contacts.findIndex(
        (item: any) => item.key === contactId
      );
      const newContacts = [...contacts.contacts];
      newContacts[findContact].name = newContact.name;
      newContacts[findContact].lastname = newContact.surname;
      newContacts[findContact].email = newContact.email;
      newContacts[findContact].phoneNumberPrefix = newContact.telephone_prefix;
      newContacts[findContact].phoneNumberSufix = newContact.telephone_sufix;
      newContacts[findContact].department = newContact.department;
      dispatch({
        type: MainContactsTypes.UPDATE_CONTACT,
        payload: newContacts,
      });
      messageSuccess('Contacto actualizado con correctamente.');
      return setIsOpenDrawer(false);
    } else {
      messageError('Error al actualizar el contacto.');
      notificationErr(mainContactError.update_contact_001, translate);
    }
  } catch (err) {
    messageError('Error al actualizar el contacto.');
    notificationErr(mainContactError.update_contact_002, translate);
  }
}

export async function removeContactAction(
  dispatch: Dispatch<any>,
  contactId: string,
  contacts: IMainContactItemState[],
  token: string,
  translate: ITranslate
) {
  try {
    const removeFetch = await api.removeMainContactAPI(contactId, token);
    if (removeFetch.contact === 1) {
      const newContacts = [...contacts];
      const filteredContacts = newContacts.filter((item: any) => item.key !== contactId);
      dispatch({
        type: MainContactsTypes.REMOVE_CONTACT,
        payload: filteredContacts,
      });
      return messageSuccess('Contacto eliminado correctamente.');
    } else {
      messageError('Error al eliminar el contacto.');
      notificationErr(mainContactError.remove_contact_001, translate);
    }
  } catch (err) {
    messageError('Error al eliminar el contacto.');
    notificationErr(mainContactError.remove_contact_002, translate);
  }
}
