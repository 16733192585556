import React from 'react';
import * as MetaTypes from '../meta.types';
import { metaError } from 'utils/errorCodes';
import { notificationErr } from 'views/components/UI/notification';
import * as msg from 'views/components/UI/message';
import * as api from 'api/endpoints/meta/meta.api';
import * as metaResponseInterface from 'api/endpoints/meta/meta.response.interface';
import * as metaInterface from 'interfaces/meta.interface';
import { IMapField } from 'interfaces/superAdmin.interface';
import { ITranslate } from 'interfaces/general.interface';

export async function getMapsAction({
  dispatch,
  brandId,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  brandId: string;
  token: string;
  translate: ITranslate;
}) {
  dispatch({
    type: MetaTypes.SET_IS_LOADING_MAPS,
    payload: true,
  });
  try {
    const getMapsFetch = await api.getMapsAPI({ brandId, token });
    if (getMapsFetch.response.status === 200 || getMapsFetch.response.status === 201) {
      dispatch({
        type: MetaTypes.GET_MAPS,
        payload: getMapsFetch.data,
      });
      return true;
    }
    return false;
  } catch (error) {
    msg.messageError('Error del servidor al obtener los mapas');
    notificationErr(metaError.get_maps_001, translate);
    return false;
  } finally {
    dispatch({
      type: MetaTypes.SET_IS_LOADING_MAPS,
      payload: false,
    });
  }
}

export function setMapSelectedAction({
  dispatch,
  mapId,
}: {
  dispatch: React.Dispatch<any>;
  mapId: string | null;
}) {
  dispatch({
    type: MetaTypes.SET_MAP_SELECTED,
    payload: mapId,
  });
}

export async function createMapAction({
  dispatch,
  connectorType,
  body,
  connection,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  connectorType?: metaInterface.TConnectorTypes;
  body: metaResponseInterface.TcreateMapBodyProps;
  connection?: metaInterface.TSftpUserItem | metaInterface.TShopifyConnectionItem;
  token: string;
  translate: ITranslate;
}) {
  dispatch({
    type: MetaTypes.SET_IS_LOADING_CREATE_MAP,
    payload: true,
  });
  try {
    const createMapFetch = await api.createMapAPI({ body, token });
    if (createMapFetch.response.status === 405) {
      msg.messageWarning('Ya existe un mapa con el mismo tag');
      return null;
    }
    if (
      createMapFetch.response.status === 200 ||
      createMapFetch.response.status === 201
    ) {
      // if (connection?.id) {
      // try {
      //   // Add map to conector
      //   const maps = [
      //     ...(
      //       connection as metaInterface.TShopifyConnectionItem
      //     ).shopify_connector_maps.map((item) => item.id),
      //     createMapFetch.data.id,
      //   ];
      //   const addMapToConnector = await api.addMapToConnectorAPI({
      //     connectorId: connection.id,
      //     maps,
      //     token,
      //   });
      //   if (
      //     addMapToConnector.response.status === 200 ||
      //     addMapToConnector.response.status === 201
      //   ) {
      //     dispatch({
      //       type: MetaTypes.CREATE_MAP,
      //       payload: createMapFetch.data,
      //     });
      //     if (connectorType) {
      //       dispatch({
      //         type:
      //           connectorType === metaInterface.connectorTypes.shopify
      //             ? MetaTypes.UPDATE_SHOPIFY_CONNECTION
      //             : MetaTypes.UPDATE_SHOPIFY_CONNECTION,
      //         payload: {
      //           id: connection.id,
      //           data: {
      //             ...connection,
      //             shopify_connector_maps: maps,
      //           },
      //         },
      //       });
      //     }
      //     msg.messageSuccess('Mapa creado con éxito y añadido al conector');
      //     return createMapFetch.data.id;
      //   }
      //   notificationErr(metaError.create_map_004, translate);
      //   return null;
      // } catch (error) {
      //   msg.messageError('Error del servidor al añadir el mapa al conector');
      //   notificationErr(metaError.create_map_003, translate);
      //   return null;
      // }
      // } else {
      // }
      dispatch({
        type:
          connectorType === metaInterface.connectorTypes.shopify
            ? MetaTypes.CREATE_MAP
            : MetaTypes.CREATE_MAP,
        payload: createMapFetch.data,
      });
      msg.messageSuccess('Mapa creado con éxito.');
      return createMapFetch.data.id;
    }
    msg.messageError('Error al crear el mapa');
    notificationErr(metaError.create_map_002, translate);
    return null;
  } catch (error) {
    msg.messageError('Error del servidor al crear el mapa');
    notificationErr(metaError.create_map_001, translate);
    return null;
  } finally {
    dispatch({
      type: MetaTypes.SET_IS_LOADING_CREATE_MAP,
      payload: false,
    });
  }
}

export async function deleteMapAction({
  dispatch,
  mapId,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  mapId: string;
  token: string;
  translate: ITranslate;
}) {
  dispatch({
    type: MetaTypes.SET_IS_LOADING_DELETE_MAP,
    payload: {
      loading: true,
      mapId,
    },
  });
  try {
    const deleteMapFetch = await api.deleteMapAPI({ mapId, token });
    if (
      deleteMapFetch.response.status === 200 ||
      deleteMapFetch.response.status === 201
    ) {
      dispatch({
        type: MetaTypes.DELETE_MAP,
        payload: mapId,
      });
      msg.messageSuccess('Mapa eliminado con éxito');
      return true;
    }
    msg.messageError('Error al eliminar el mapa');
    notificationErr(metaError.delete_map_002, translate);
    return false;
  } catch (error) {
    msg.messageError('Error del servidor al eliminar el mapa');
    notificationErr(metaError.delete_map_001, translate);
    return false;
  } finally {
    dispatch({
      type: MetaTypes.SET_IS_LOADING_DELETE_MAP,
      payload: {
        loading: false,
        mapId: null,
      },
    });
  }
}

export async function updateMapAction({
  dispatch,
  connectorType,
  body,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  connectorType?: string;
  body: metaResponseInterface.TupdateMapBodyProps;
  token: string;
  translate: ITranslate;
}) {
  dispatch({
    type: MetaTypes.SET_IS_LOADING_UPDATE_MAP,
    payload: true,
  });
  try {
    const createMapFetch = await api.updateMapAPI({ body, token });
    if (
      createMapFetch.response.status === 200 ||
      createMapFetch.response.status === 201
    ) {
      dispatch({
        type: MetaTypes.UPDATE_MAP,
        payload: {
          id: body.filter.id,
          data: body.update,
        },
      });
      msg.messageSuccess('Mapa actualizado con éxito');
      return true;
    }
    msg.messageError('Error al actualizar el mapa');
    notificationErr(metaError.update_map_002, translate);
    return false;
  } catch (error) {
    msg.messageError('Error del servidor al actualizar el mapa');
    notificationErr(metaError.update_map_001, translate);
    return false;
  } finally {
    dispatch({
      type: MetaTypes.SET_IS_LOADING_UPDATE_MAP,
      payload: false,
    });
  }
}

export async function addMapToConnectorAction({
  dispatch,
  companyId,
  connectorType,
  mapIds,
  connectionId,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  companyId: string;
  connectorType: string;
  mapIds: string[];
  connectionId: string;
  token: string;
  translate: ITranslate;
}) {
  dispatch({
    type: MetaTypes.SET_IS_LOADING_ADD_MAP_TO_CONNECTION,
    payload: true,
  });
  try {
    const addMapToConnectorFetch = await api.addMapToConnectorAPI({
      connectorId: connectionId,
      maps: mapIds
        .filter((mapId) => mapId !== null)
        .filter((mapId) => mapId !== undefined),
      token,
    });
    if (
      addMapToConnectorFetch.response.status === 200 ||
      addMapToConnectorFetch.response.status === 201
    ) {
      try {
        const getConnectionsFetch = await api.getConnectionsAPI({
          body: { brand_id: companyId, index: 0, limit: 1000 },
          token,
        });
        if (getConnectionsFetch.response.status === 200) {
          if (getConnectionsFetch.data.sftp?.length > 0) {
            dispatch({
              type: MetaTypes.SET_SFTP_CONNECTIONS,
              payload: getConnectionsFetch.data.sftp.map((connection) => ({
                ...connection,
                type: metaInterface.connectorTypes.sftp,
              })),
            });
          }
          if (getConnectionsFetch.data.shopify?.length > 0) {
            dispatch({
              type:
                connectorType === metaInterface.connectorTypes.shopify
                  ? MetaTypes.SET_SHOPIFY_CONNECTIONS
                  : MetaTypes.SET_SHOPIFY_CONNECTIONS,
              payload: getConnectionsFetch.data.shopify.map((connection, index) => ({
                ...connection,
                type: metaInterface.connectorTypes.shopify,
                shopify_connector_maps: connection.shopify_connector_maps.map(
                  (item) => item.map
                ),
              })),
            });
            msg.messageSuccess('Mapa añadido al conector con éxito');
            return true;
          }
          return true;
        }
        return false;
      } catch (err) {
        msg.messageError(translate('action_meta_get-connections_error'));
        notificationErr(metaError.get_connections_001, translate);
        return false;
      }
    }
    msg.messageError('Error al añadir el mapa al conector');
    notificationErr(metaError.add_map_to_connector_002, translate);
    return false;
  } catch (error) {
    msg.messageError('Error del servidor al añadir el mapa al conector');
    notificationErr(metaError.add_map_to_connector_001, translate);
    return false;
  } finally {
    dispatch({
      type: MetaTypes.SET_IS_LOADING_ADD_MAP_TO_CONNECTION,
      payload: false,
    });
  }
}

export async function deleteMapFromConnectorAction({
  dispatch,
  connectorType,
  mapIds,
  connectionId,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  connectorType?: string;
  mapIds: string[];
  connectionId: string;
  token: string;
  translate: ITranslate;
}) {
  dispatch({
    type: MetaTypes.SET_IS_LOADING_DELETE_MAP_FROM_CONNECTION,
    payload: true,
  });
  try {
    const deleteMapFromConnectorFetch = await api.addMapToConnectorAPI({
      connectorId: connectionId,
      maps: mapIds,
      token,
    });
    if (
      deleteMapFromConnectorFetch.response.status === 200 ||
      deleteMapFromConnectorFetch.response.status === 201
    ) {
      dispatch({
        type:
          connectorType === metaInterface.connectorTypes.shopify
            ? MetaTypes.UPDATE_SHOPIFY_CONNECTION
            : MetaTypes.UPDATE_SHOPIFY_CONNECTION,
        payload: {
          id: connectionId,
          data: { shopify_connector_maps: mapIds },
        },
      });
      msg.messageSuccess('Mapa eliminado del conector con éxito');
      return true;
    }
    msg.messageError('Error al eliminar el mapa del conector');
    notificationErr(metaError.delete_map_from_connector_002, translate);
    return false;
  } catch (error) {
    msg.messageError('Error del servidor al eliminar el mapa del conector');
    notificationErr(metaError.delete_map_from_connector_001, translate);
    return false;
  } finally {
    dispatch({
      type: MetaTypes.SET_IS_LOADING_DELETE_MAP_FROM_CONNECTION,
      payload: false,
    });
  }
}

export async function addMapFieldsToMapAction({
  dispatch,
  mapId,
  mapFields,
  mappings,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  mapId: string;
  mapFields: IMapField[];
  mappings: metaInterface.IMap[];
  token: string;
  translate: ITranslate;
}) {
  // dispatch({
  //   type: MetaTypes.SET_IS_LOADING_ADD_MAP_FIELDS_TO_MAP,
  //   payload: true,
  // });
  try {
    const mapSelectedFieldsIdx = mappings
      .find((map) => map.id === mapId)
      ?.map_field_maps.map((mapField) => mapField.map_field_id);
    const addFieldToMap = await api.addFieldToMapAPI({
      mapId,
      mapFieldsIdx:
        mapSelectedFieldsIdx && mapSelectedFieldsIdx.length > 0
          ? [...mapSelectedFieldsIdx, ...mapFields.map((mapField) => mapField.id)]
          : [...mapFields.map((mapField) => mapField.id)],
      token,
    });

    console.log('mapFields', mapFields);
    console.log('addFieldToMap', addFieldToMap);

    const newMapFields = addFieldToMap.data.data.map((mapField) => ({
      ...mapField,
      map_field: mapFields.find((field) => field.id === mapField.map_field_id),
    }));

    if (addFieldToMap.response.status === 200 || addFieldToMap.response.status === 201) {
      dispatch({
        type: MetaTypes.ADD_FIELDS_TO_MAP,
        payload: {
          mapId: mapId,
          mapFields: newMapFields,
        },
      });
      msg.messageSuccess('Instrucciones añadidas al mapa con éxito');
      return true;
    }

    // const addMapFieldsToMapFetch = await api.addMapFieldsToMapAPI({
    //   mapId,
    //   mapFields,
    //   token,
    // });
    // if (
    //   addMapFieldsToMapFetch.response.status === 200 ||
    //   addMapFieldsToMapFetch.response.status === 201
    // ) {
    //   dispatch({
    //     type: MetaTypes.ADD_MAP_FIELDS_TO_MAP,
    //     payload: {
    //       mapId,
    //       mapFields: addMapFieldsToMapFetch.data.map_fields,
    //     },
    //   });
    //   msg.messageSuccess('Instrucciones añadidas al mapa con éxito');
    //   return true;
    // }
    msg.messageError('Error al añadir las instrucciones al mapa');
    notificationErr(metaError.add_map_fields_to_map_002, translate);
    return false;
  } catch (error) {
    msg.messageError('Error del servidor al añadir las instrucciones al mapa');
    notificationErr(metaError.add_map_fields_to_map_001, translate);
    return false;
  } finally {
    // dispatch({
    //   type: MetaTypes.SET_IS_LOADING_ADD_MAP_FIELDS_TO_MAP,
    //   payload: false,
    // });
  }
}
