import { langs } from 'contexts/UI/language/LanguageProvider';
import * as GenericTypes from './catalogGeneric.types';
import {
  addGenericsAPI,
  getGenericsAPI,
  updateGenericAPI,
} from 'api/endpoints/generics/generics.api';
import {
  IGenericType,
  IInitialGenericState,
  TGenericValues,
  TUpdateGenericBody,
} from 'interfaces/generics.interface';
import { catalogGenericsError } from 'utils/errorCodes';
import {
  messageError,
  messageSuccess,
  messageWarning,
} from 'views/components/UI/message';
import { notificationErr } from 'views/components/UI/notification';
import { IGenericItem } from 'api/endpoints/generics/generics.response.interface';
import { ITranslate } from 'interfaces/general.interface';
import { Dispatch } from 'react';

type TgetGenericsActionProps = {
  dispatch: Dispatch<any>;
  type: IGenericType;
  token: string;
  translate: ITranslate;
};
export async function getGenericsAction({
  dispatch,
  type,
  token,
  translate,
}: TgetGenericsActionProps) {
  dispatch({ type: GenericTypes.SET_IS_LOADING_GENERICS, payload: true });
  try {
    const getGenericsFetch = await getGenericsAPI(type, token);
    if (getGenericsFetch.response.status === 200) {
      return dispatch({
        type: GenericTypes.SET_GENERICS,
        payload: {
          type: type,
          data: getGenericsFetch.data[type as keyof typeof getGenericsFetch.data],
        },
      });
    } else {
      return notificationErr(catalogGenericsError.get_generics_002, translate);
    }
  } catch (error) {
    return notificationErr(catalogGenericsError.get_generics_001, translate);
  } finally {
    dispatch({ type: GenericTypes.SET_IS_LOADING_GENERICS, payload: false });
  }
}

type TaddGenericsActionProps = {
  dispatch: Dispatch<any>;
  type: IGenericType;
  generics: TGenericValues[];
  token: string;
  translate: ITranslate;
};
export async function addGenericsAction({
  dispatch,
  type,
  generics,
  token,
  translate,
}: TaddGenericsActionProps) {
  try {
    const addGenericsFetch = await addGenericsAPI(type, generics, token);
    if (addGenericsFetch.response.status === 200) {
      messageSuccess('Genérico añadido correctamente');
      return addGenericsFetch.data[0].item.group_id;
    } else {
      if (addGenericsFetch.response.status === 500) {
        messageWarning('Genérico duplicado');
      } else {
        messageError('Error al añadir el genérico');
        notificationErr(catalogGenericsError.add_generics_002, translate);
      }
    }
    return await getGenericsAction({ dispatch, type, token, translate });
  } catch (error) {
    notificationErr(catalogGenericsError.add_generics_001, translate);
  }
}

type TUpdateGenericAction = {
  dispatch: Dispatch<any>;
  genericState: IInitialGenericState;
  body: TUpdateGenericBody;
  token: string;
  translate: ITranslate;
};
export async function updateGenericAction({
  dispatch,
  genericState,
  body,
  token,
  translate,
}: TUpdateGenericAction) {
  try {
    const updateGenericFetch = await updateGenericAPI(body, token);
    if (updateGenericFetch.response.status === 200) {
      const findOldGeneric = (genericState.generics[body.option] as IGenericItem[]).find(
        (item) => item.group_id === body.group_id
      ) as IGenericItem;
      if (findOldGeneric) {
        const newGeneric = {
          group_id: body.group_id,
          value: findOldGeneric.value || '',
          generics: [
            { value: body.items.es, i18n_lang: { code: langs.es } },
            { value: body.items.it, i18n_lang: { code: langs.it } },
            { value: body.items['en-gb'], i18n_lang: { code: langs.en } },
            { value: body.items.fr, i18n_lang: { code: langs.fr } },
          ],
        };
        dispatch({
          type: GenericTypes.UPDATE_GENERIC,
          payload: {
            type: body.option,
            newGeneric: newGeneric,
          },
        });
      }
      messageSuccess('Genérico actualizado correctamente');
      return true;
    } else {
      messageError('Error al actualizar el genérico');
      notificationErr(catalogGenericsError.update_generics_002, translate);
      return false;
    }
  } catch (error) {
    notificationErr(catalogGenericsError.update_generics_001, translate);
  }
}
