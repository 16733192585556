import * as CatalogCorrelationsTypes from './catalogCorrelations.types';
import { IGenericType } from 'interfaces/generics.interface';
import {
  assignGenericsAPI,
  getCatalogCorrelationsAPI,
} from 'api/endpoints/catalogCorrelation/catalogCorrelation.api';
import { TLangType } from 'contexts/UI/language/LanguageProvider';
import { messageError, messageSuccess } from 'views/components/UI/message';
import {
  TCatalogCorrelationDataSource,
  TValuesToAssign,
} from 'interfaces/catalogCorrelations.interface';
import { notificationErr } from 'views/components/UI/notification';
import { catalogCorrelationsError } from 'utils/errorCodes';
import { ITranslate } from 'interfaces/general.interface';
import { Dispatch } from 'react';

type TgetGenericsActionProps = {
  dispatch: Dispatch<any>;
  brandId: string;
  language: TLangType;
  type: IGenericType;
  token: string;
  translate: ITranslate;
};
export async function getGenericsAction({
  dispatch,
  brandId,
  language,
  type,
  token,
  translate,
}: TgetGenericsActionProps) {
  dispatch({ type: CatalogCorrelationsTypes.SET_IS_LOADING, payload: true });
  try {
    const getCatalogCorrelationsFetch = await getCatalogCorrelationsAPI(
      brandId,
      type,
      token
    );
    if (getCatalogCorrelationsFetch.response.status === 200) {
      let values: TCatalogCorrelationDataSource[] = [];
      getCatalogCorrelationsFetch.data.forEach((correlation, index) => {
        const itemValue = {
          key: index,
          label: correlation.generics.find(
            (generic) => generic.i18n_lang.code === language
          )?.value as TLangType,
          value: correlation.id,
          values: correlation.items.map((item) => item.id),
        };
        values.push(itemValue);
      });
      return dispatch({
        type: CatalogCorrelationsTypes.SET_CORRELATIONS,
        payload: {
          type: type,
          data: values,
        },
      });
    } else {
      return notificationErr(catalogCorrelationsError.get_correlations_002, translate);
    }
  } catch (error) {
    notificationErr(catalogCorrelationsError.get_correlations_001, translate);
  } finally {
    dispatch({ type: CatalogCorrelationsTypes.SET_IS_LOADING, payload: false });
  }
}

type TassignGenericAction = {
  dispatch: Dispatch<any>;
  brandId: string;
  language: TLangType;
  type: IGenericType;
  values: TValuesToAssign;
  token: string;
  translate: ITranslate;
};
export async function assignGenericAction({
  dispatch,
  brandId,
  language,
  type,
  values,
  token,
  translate,
}: TassignGenericAction) {
  dispatch({
    type: CatalogCorrelationsTypes.SET_IS_LOADING_ASSIGN_GENERICS,
    payload: true,
  });
  try {
    const assignGenericsFetch = await assignGenericsAPI(brandId, values, token);
    if (assignGenericsFetch.response.status === 200) {
      messageSuccess(translate('catalog-correlation_assign-generics_success'));
    } else {
      messageError(translate('catalog-correlation_assign-generics_error'));
      notificationErr(catalogCorrelationsError.assign_generics_002, translate);
    }
    return await getGenericsAction({
      dispatch,
      brandId,
      language: language,
      type: type,
      token,
      translate,
    });
  } catch (error) {
    notificationErr(catalogCorrelationsError.assign_generics_001, translate);
  } finally {
    dispatch({
      type: CatalogCorrelationsTypes.SET_IS_LOADING_ASSIGN_GENERICS,
      payload: false,
    });
  }
}
