import { InitialGeneralState } from 'interfaces/general.interface';
import * as GeneralTypes from './general.types';

export default function generalReducer(state: InitialGeneralState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case GeneralTypes.SET_EXCHANGES: {
      const setExchanges: InitialGeneralState = {
        ...state,
        exchanges: payload,
      };
      return setExchanges;
    }

    case GeneralTypes.SET_COUNTRIES:
      const setCountries: InitialGeneralState = {
        ...state,
        countries: payload,
      };
      return setCountries;

    case GeneralTypes.SET_ASSOCIATIONS_STATES: {
      const setAssociationsStates: InitialGeneralState = {
        ...state,
        associationStates: payload,
      };
      return setAssociationsStates;
    }

    default:
      return state;
  }
}
