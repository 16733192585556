import * as UserTypes from './user.types';
import { ICompanyUpdateFields, IInitialUserState } from 'interfaces/user.interface';
import moment from 'moment';
import { TCompanyAPIResponse } from 'api/endpoints/company/company.response.interface';
import {
  IGetExchangesAPIResponseItem,
  TLanguageResponseItem,
} from 'api/endpoints/general/general.response.interface';
import { ISAdminUserTenantState } from 'api/endpoints/superAdmin/superAdmin.response.interface';

export default function userReducer(state: IInitialUserState, action: any) {
  const { type, payload } = action;
  const companyResponse = payload.company as TCompanyAPIResponse;

  switch (type) {
    case UserTypes.LOGIN_USER: {
      const loginUser: IInitialUserState = {
        ...state,
        dbUser: {
          ...state.dbUser,
          id: payload.user.id,
          auth0Id: payload.user.auth_id,
          email: payload.user.email,
          name: payload.user.name,
          surname: payload.user.surname,
          telephone_prefix: payload.user.telephone_prefix,
          telephone_sufix: payload.user.telephone_sufix,
          profile_image: payload?.user?.profile_image
            ? `${payload?.user?.profile_image}?ts=${moment().unix()}`
            : '',
          language: {
            ...state.dbUser.language,
            id: payload.user?.i18n_lang?.id ?? 'd54451a1-674a-4f97-a769-acff8ab795bb',
            code: payload.user?.i18n_lang?.code ?? 'es',
          },
          role: payload.user.role,
          sector: payload.user.sector,
          signed: payload.user.signed,
          first_login: payload.user.first_login,
          enabled: payload.user.enabled,
          user_tenants: payload.user.user_tenants,
        },
        company: {
          ...state.company,
          ...companyResponse,
          logo: companyResponse?.logo
            ? (`${companyResponse.logo as string}?ts=${moment().unix()}` as string)
            : '',
        },
      };
      return loginUser;
    }

    case UserTypes.LOGIN_COMPANY_USER_SUPER_ADMIN: {
      const user = payload.user as ISAdminUserTenantState;
      const loginCompanyUserSuperAdmin: IInitialUserState = {
        ...state,
        dbUser: {
          ...state.dbUser,
          id: user.user_id,
          auth0Id: user.user.auth_id,
          email: user.user.username,
          name: user.user.name,
          surname: user.user.surname,
          telephone_prefix: user.user.tel_prefix,
          telephone_sufix: user.user.tel_sufix,
          profile_image: user.user.profile_image
            ? `${user.user.profile_image}?ts=${moment().unix()}`
            : '',
          language: {
            ...state.dbUser.language,
            id: user.user.i18n_lang_id ?? 'd54451a1-674a-4f97-a769-acff8ab795bb',
            code:
              payload?.languages?.find(
                (lang: TLanguageResponseItem) => lang.id === user.user.i18n_lang_id
              )?.code ?? 'es',
          },
          sector: user.user.sector,
          signed: user.user.signed,
          first_login: user.user.first_login,
          enabled: user.user.enabled,
        },
        company: {
          ...state.company,
          ...companyResponse,
          logo: companyResponse?.logo
            ? (`${companyResponse.logo as string}?ts=${moment().unix()}` as string)
            : '',
        },
      };

      return loginCompanyUserSuperAdmin;
    }

    case UserTypes.LOGIN_COMPANY_SUPER_ADMIN: {
      const loginCompanySuperAdmin: IInitialUserState = {
        ...state,
        company: {
          ...state.company,
          ...companyResponse,
        },
      };
      return loginCompanySuperAdmin;
    }

    case UserTypes.UPDATE_USER: {
      const updateUser: IInitialUserState = {
        ...state,
        dbUser: {
          ...state.dbUser,
          name: payload.name,
          surname: payload.surname,
          telephone_prefix: payload.tel_prefix,
          telephone_sufix: payload.tel_sufix,
          sector: payload.sector,
        },
      };
      return updateUser;
    }

    case UserTypes.UPDATE_COMPANY: {
      const {
        name,
        legal_name,
        province,
        city,
        address,
        country_id,
        country_name,
        country_iso_code,
        postal_code,
        floor,
        door,
        lat,
        lng,
        email,
        tel_prefix,
        tel_sufix,
        cif,
        contact_name,
        web,
        instagram,
        facebook,
        youtube,
        linkedin,
        currency_id,
      } = payload.companyData as ICompanyUpdateFields;
      const updateCompany: IInitialUserState = {
        ...state,
        company: {
          ...state.company,
          name: name ?? '',
          legal_name: legal_name ?? '',
          province: province ?? '',
          city: city ?? '',
          address: address ?? '',
          country: {
            id: country_id ?? '',
            name: country_name ?? '',
            iso_code: country_iso_code ?? '',
          },
          postal_code: postal_code ?? '',
          floor: floor ?? '',
          door: door ?? '',
          lat: lat ?? 0,
          lng: lng ?? 0,
          email: email ?? '',
          tel_prefix: tel_prefix ?? '',
          tel_sufix: tel_sufix ?? '',
          cif: cif ?? '',
          contact_name: contact_name ?? '',
          web: web ?? '',
          instagram: instagram ?? '',
          youtube: youtube ?? '',
          linkedin: linkedin ?? '',
          facebook: facebook ?? '',
          currency_id: currency_id ?? '',
          exchange:
            (payload.exchanges as Array<IGetExchangesAPIResponseItem>)?.find(
              (exchange) => exchange.id === currency_id
            ) ?? null,
        },
      };
      return updateCompany;
    }

    case UserTypes.UPDATE_USER_AVATAR: {
      const updateUserAvatar: IInitialUserState = {
        ...state,
        dbUser: {
          ...state.dbUser,
          profile_image: `${payload.url}?ts=${moment().unix()}`,
        },
      };
      return updateUserAvatar;
    }

    case UserTypes.UPDATE_COMPANY_LOGO: {
      const updateCompanyLogo: IInitialUserState = {
        ...state,
        company: {
          ...state.company,
          logo: `${payload.url as string}?ts=${moment().unix()}` as string,
        },
      };
      return updateCompanyLogo;
    }

    case UserTypes.VERIFY_EMAIL: {
      const verifyEmail: IInitialUserState = {
        ...state,
        dbUser: {
          ...state.dbUser,
          enabled: payload,
        },
      };
      return verifyEmail;
    }

    case UserTypes.UPDATE_LANGUAGE: {
      const updateLanguage: IInitialUserState = {
        ...state,
        dbUser: {
          ...state.dbUser,
          language: payload,
        },
      };
      return updateLanguage;
    }

    case UserTypes.ADD_SOCIAL_MEDIA: {
      const addSocialMedia: IInitialUserState = {
        ...state,
        company: {
          ...state.company,
          tenant_social_media: [...state.company.tenant_social_media, payload],
        },
      };
      return addSocialMedia;
    }

    case UserTypes.SET_SOCIAL_MEDIA: {
      const setSocialMedia: IInitialUserState = {
        ...state,
        company: {
          ...state.company,
          tenant_social_media: payload,
        },
      };
      return setSocialMedia;
    }

    case UserTypes.SET_IS_LOADING: {
      const setIsLoading: IInitialUserState = {
        ...state,
        isLoading: payload,
      };
      return setIsLoading;
    }

    default:
      return state;
  }
}
