import { CommercialInfo } from 'interfaces/commercialInfo.interface';

export const comercialInfoContact = {
  key: '',
  email: '',
  name: '',
  lastname: '',
  tradeName: '',
  phonePrefix: '',
  phoneSufix: '',
  divisions: [],
  salesGroup: false,
  sector: '',
  cif: '',
  workingBrands: [{ id: 0, brandName: '' }],
  // businessName: 'NeoSport s.a.',
  // mainContact: 'Contacto principal',
};

const initialComercialInfoState = {
  contacts: [comercialInfoContact],
  contactSelected: comercialInfoContact,
  getCommercialInfo: () => {},
  selectComercialContact: (contact: any) => {},
  updateCommercialInfoContact: (
    newContact: CommercialInfo,
    setIsOpenDrawer: () => void
  ) => {},
  createCommercialInfoContact: (
    newContact: CommercialInfo,
    setIsOpenDrawer: () => void
  ) => {},
  removeCommercialAgent: (contactId: string) => {},
};

export default initialComercialInfoState;
