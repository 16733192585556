import { memo, useCallback, useMemo, useState } from 'react';
import { GoogleAddressContext } from './GoogleAddressContext';
import { ChildrenProps } from 'interfaces/general.interface';

const GoogleAddressProvider = ({ children }: ChildrenProps) => {
  const [googleAddress, setGoogleAddressAction] = useState({
    address: '',
    postal_code: '',
    country_id: '',
    city: '',
    province: '',
    floor: '',
    door: '',
    coordinates: {
      lat: 0.0,
      lng: 0.0,
    },
  });

  const setGoogleAddress = useCallback(
    (field: any, value: string) => {
      setGoogleAddressAction({ ...googleAddress, [field]: value });
    },
    [googleAddress]
  );

  const autoSetGoogleAddress = useCallback(
    (
      address: string,
      postal_code: string,
      country_id: string,
      city: string,
      province: string,
      floor: string,
      door: string,
      coordinates: any
    ) => {
      setGoogleAddressAction({
        ...googleAddress,
        address,
        postal_code,
        country_id,
        city,
        province,
        floor,
        door,
        coordinates,
      });
    },
    [googleAddress]
  );

  const resetGoogleAddress = useCallback((values: any) => {
    setGoogleAddressAction({
      address: values?.address ? values.address : '',
      postal_code: values?.postal_code ? values.postal_code : '',
      country_id: values?.country_id ? values.country_id : '',
      city: values?.city ? values.city : '',
      province: values?.province ? values.province : '',
      floor: values?.floor ? values.floor : '',
      door: values?.door ? values.door : '',
      coordinates: values?.coordinates
        ? values.coordinates
        : {
            lat: 0.0,
            lng: 0.0,
          },
    });
  }, []);

  const memoProvider = useMemo(
    () => ({
      googleAddress,
      setGoogleAddress,
      autoSetGoogleAddress,
      resetGoogleAddress,
    }),
    [googleAddress, setGoogleAddress, autoSetGoogleAddress, resetGoogleAddress]
  );

  return (
    <GoogleAddressContext.Provider value={memoProvider}>
      {children}
    </GoogleAddressContext.Provider>
  );
};

export default memo(GoogleAddressProvider);
