import { TSocialMedia } from 'interfaces/user.interface';
import {
  IaddSocialMediaAPIResponse,
  IGetCompanyInfoAPIResponse,
  IremoveSocialMediaAPIResponse,
  IupdateSocialMediaAPIResponse,
  IupdateTenantDataAPIBody,
  IupdateTenantDataAPIResponse,
} from 'api/endpoints/company/company.response.interface';
import { getBasePath } from '../../utils/config';

export const getCompanyInfoAPI = async ({
  tenantId,
  countryId,
  token,
}: {
  tenantId: string;
  countryId: string;
  token: string;
}): Promise<IGetCompanyInfoAPIResponse> => {
  const params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${getBasePath()}/brand_profile/get_tenant_profile?tenant_id=${tenantId}&country_id=${countryId}`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const updateTenantDataAPI = async ({
  body,
  token,
}: {
  body: IupdateTenantDataAPIBody;
  token: string;
}): Promise<IupdateTenantDataAPIResponse> => {
  const params = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(
    `${getBasePath()}/brand_profile/update_tenant_profile`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const addSocialMediaAPI = async ({
  tenantId,
  items,
  token,
}: {
  tenantId: string;
  items: Array<TSocialMedia>;
  token: string;
}): Promise<IaddSocialMediaAPIResponse> => {
  const params = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      tenant_id: tenantId,
      items: items,
    }),
  };
  const response = await fetch(`${getBasePath()}/social_media/add_social_media`, params);
  const data = await response.json();
  return { response, data };
};

export const removeSocialMediaAPI = async ({
  socialMediaId,
  token,
}: {
  socialMediaId: string;
  token: string;
}): Promise<IremoveSocialMediaAPIResponse> => {
  const params = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      target: 'tenant',
      ids: [socialMediaId],
    }),
  };
  const response = await fetch(
    `${getBasePath()}/social_media/delete_social_media`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const updateSocialMediaAPI = async ({
  tenant_id,
  id,
  url,
  name,
  token,
}: {
  tenant_id: string;
  id: string;
  url: string;
  name: string;
  token: string;
}): Promise<IupdateSocialMediaAPIResponse> => {
  const params = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      tenant_id: tenant_id,
      id: id,
      url: url,
      name: name,
    }),
  };
  const response = await fetch(
    `${getBasePath()}/social_media/update_social_media`,
    params
  );
  const data = await response.json();
  return { response, data };
};
