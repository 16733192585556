import React from 'react';
import * as MetaTypes from '../meta.types';
import { metaError } from 'utils/errorCodes';
import { notificationErr } from 'views/components/UI/notification';
import * as msg from 'views/components/UI/message';
import * as api from 'api/endpoints/meta/meta.api';
import * as metaResponseInterface from 'api/endpoints/meta/meta.response.interface';
import * as metaInterface from 'interfaces/meta.interface';
import { ITranslate } from 'interfaces/general.interface';

export async function getConnectionsAction({
  dispatch,
  body,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  body: metaResponseInterface.TgetConnectorsAPIBodyProps;
  token: string;
  translate: ITranslate;
}) {
  dispatch({ type: MetaTypes.SET_IS_LOADING_CONNECTIONS, payload: true });
  try {
    const getConnectionsFetch = await api.getConnectionsAPI({ body, token });
    if (getConnectionsFetch.response.status === 200) {
      if (getConnectionsFetch.data.sftp?.length > 0) {
        dispatch({
          type: MetaTypes.SET_SFTP_CONNECTIONS,
          payload: getConnectionsFetch.data.sftp.map((connection) => ({
            ...connection,
            type: metaInterface.connectorTypes.sftp,
          })),
        });
      }
      if (getConnectionsFetch.data.shopify?.length > 0) {
        dispatch({
          type: MetaTypes.SET_SHOPIFY_CONNECTIONS,
          payload: getConnectionsFetch.data.shopify.map((connection, index) => ({
            ...connection,
            type: metaInterface.connectorTypes.shopify,
            shopify_connector_maps: connection.shopify_connector_maps.map(
              (item) => item.map
            ),
          })),
        });
      }
      return true;
    }
    return false;
  } catch (err) {
    msg.messageError(translate('action_meta_get-connections_error'));
    notificationErr(metaError.get_connections_001, translate);
    return false;
  } finally {
    dispatch({ type: MetaTypes.SET_IS_LOADING_CONNECTIONS, payload: false });
  }
}
