import { ICatalogState, TQuerySearchArray } from 'interfaces/catalog.interface';
import * as CatalogTypes from './catalog.types';

export default function catalogReducer(state: ICatalogState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case CatalogTypes.GET_CATALOG:
      const getCatalog: ICatalogState = {
        ...state,
        catalogItems: payload,
      };
      return getCatalog;

    case CatalogTypes.SET_RELATED_PRODUCTS:
      const setRelatedProducts: ICatalogState = {
        ...state,
        relatedProducts: payload,
      };
      return setRelatedProducts;

    case CatalogTypes.ADD_MORE_TO_CATALOG:
      const addMoreToCatalog: ICatalogState = {
        ...state,
        catalogItems: {
          ...state.catalogItems,
          data: state.catalogItems.data?.concat(payload.data),
        },
      };
      return addMoreToCatalog;

    case CatalogTypes.GET_PRODUCT:
      const getProduct: ICatalogState = {
        ...state,
        productItem: payload,
      };
      return getProduct;

    case CatalogTypes.UPDATE_PRODUCT: {
      const updateProduct: ICatalogState = {
        ...state,
        productItem: {
          ...state.productItem,
          [payload.fieldName]: payload.value,
        },
      };
      return updateProduct;
    }

    case CatalogTypes.SET_IS_LOADING:
      const setIsLoading: ICatalogState = {
        ...state,
        isLoading: payload,
      };
      return setIsLoading;

    case CatalogTypes.SET_IS_LOADING_MORE_PRODUCTS:
      const setIsLoadingMoreProducts: ICatalogState = {
        ...state,
        isLoadingMoreProducts: payload,
      };
      return setIsLoadingMoreProducts;

    case CatalogTypes.SET_BODY_PRODUCT:
      const setBodyProduct: ICatalogState = {
        ...state,
        bodyProductSearch: {
          ...state.bodyProductSearch,
          options: {
            ...state.bodyProductSearch.options,
            brand_id: [payload.brandId],
            language_id: payload.languageId,
            limit: payload.limit,
            index: 0,
          },
          filter: {
            reference: [],
            color: [],
            season: [],
            segmentation: [],
            division: [],
            family: [],
            gender: [],
            pvi: [],
            pvpr: [],
            tags: [],
            tiers: [],
          },
        },
        bodyProductSearchTemp: {
          ...state.bodyProductSearchTemp,
          options: {
            ...state.bodyProductSearchTemp.options,
            brand_id: [payload.brandId],
            language_id: payload.languageId,
            limit: payload.limit,
            index: 0,
          },
          filter: {
            reference: [],
            color: [],
            season: [],
            segmentation: [],
            division: [],
            family: [],
            gender: [],
            pvi: [],
            pvpr: [],
            tags: [],
            tiers: [],
          },
        },
        querysearchArray: [],
      };
      return setBodyProduct;

    case CatalogTypes.SET_BODY_PRODUCT_SEARCH:
      const setBodyProductSearch: ICatalogState = {
        ...state,
        bodyProductSearch: payload,
      };
      return setBodyProductSearch;

    case CatalogTypes.SET_BODY_PRODUCT_SEARCH_TEMP:
      const setBodyProductSearchTemp: ICatalogState = {
        ...state,
        bodyProductSearchTemp: payload,
      };
      return setBodyProductSearchTemp;

    case CatalogTypes.SET_QUERY_SEARCH:
      const setQuerySearch: ICatalogState = {
        ...state,
        querySearch: payload,
      };
      return setQuerySearch;

    case CatalogTypes.INCREMENT_PAGINATION_INDEX:
      const incrementPaginationIndex: ICatalogState = {
        ...state,
        bodyProductSearch: {
          ...state.bodyProductSearch,
          options: {
            ...state.bodyProductSearch.options,
            index: payload,
          },
        },
      };
      return incrementPaginationIndex;

    case CatalogTypes.SET_PRICE_SLIDERS:
      const setPriceSliders: ICatalogState = {
        ...state,
        bodyProductSearchTemp: {
          ...state.bodyProductSearchTemp,
          filter: {
            ...state.bodyProductSearchTemp.filter,
            [payload.field]: payload.value,
          },
        },
      };
      return setPriceSliders;

    case CatalogTypes.ADD_BODY_FILTERS:
      const addBodyFilters: ICatalogState = {
        ...state,
        bodyProductSearchTemp: {
          ...state.bodyProductSearchTemp,
          filter: {
            ...state.bodyProductSearchTemp.filter,
            [payload.field]: [
              ...state.bodyProductSearchTemp.filter[
                payload.field as keyof typeof state.bodyProductSearchTemp.filter
              ],
              payload.value,
            ],
          },
        },
      };
      return addBodyFilters;

    case CatalogTypes.REMOVE_BODY_FILTERS:
      const removeBodyFilters: ICatalogState = {
        ...state,
        bodyProductSearchTemp: {
          ...state.bodyProductSearchTemp,
          filter: {
            ...state.bodyProductSearchTemp.filter,
            [payload.field]: (
              state.bodyProductSearchTemp.filter[
                payload.field as keyof typeof state.bodyProductSearchTemp.filter
              ] as string[]
            ).filter((value) => value !== payload.value),
          },
        },
      };
      return removeBodyFilters;

    case CatalogTypes.RESET_BODY_FILTERS:
      const resetBodyFilters: ICatalogState = {
        ...state,
        bodyProductSearch: {
          ...state.bodyProductSearch,
          options: {
            ...state.bodyProductSearch.options,
            favorite: '',
            // search: [""],
            index: 0,
          },
          filter: {
            reference: [],
            color: [],
            season: [],
            segmentation: [],
            division: [],
            family: [],
            gender: [],
            pvi: [],
            pvpr: [],
            tags: [],
            tiers: [],
          },
        },
        bodyProductSearchTemp: {
          ...state.bodyProductSearchTemp,
          options: {
            ...state.bodyProductSearchTemp.options,
            favorite: '',
            // search: [""],
            index: 0,
          },
          filter: {
            reference: [],
            color: [],
            season: [],
            segmentation: [],
            division: [],
            family: [],
            gender: [],
            pvi: [],
            pvpr: [],
            tags: [],
            tiers: [],
          },
        },
        querysearchArray: [],
      };
      return resetBodyFilters;

    case CatalogTypes.RESET_PRICE_SLIDER:
      const resetPriceSlider: ICatalogState = {
        ...state,
        bodyProductSearch: {
          ...state.bodyProductSearch,
          filter: {
            ...state.bodyProductSearch.filter,
            [payload.type]: [],
          },
        },
        bodyProductSearchTemp: {
          ...state.bodyProductSearchTemp,
          filter: {
            ...state.bodyProductSearchTemp.filter,
            [payload.type]: [],
          },
        },
      };
      return resetPriceSlider;

    case CatalogTypes.SET_THERE_ARE_MORE_PRODUCTS:
      const setThereAreMoreProducts: ICatalogState = {
        ...state,
        thereAreMoreProducts: payload,
      };
      return setThereAreMoreProducts;

    case CatalogTypes.SET_IS_EMPTY_PRODUCTS:
      const setIsEmptyProducts: ICatalogState = {
        ...state,
        isEmptyProducts: payload,
      };
      return setIsEmptyProducts;

    case CatalogTypes.SET_SORT_BY:
      const setSortBy: ICatalogState = {
        ...state,
        bodyProductSearch: {
          ...state.bodyProductSearch,
          options: {
            ...state.bodyProductSearch.options,
            order: [payload],
          },
        },
      };
      return setSortBy;

    case CatalogTypes.SET_FAVORITES:
      const setFavorites: ICatalogState = {
        ...state,
        bodyProductSearchTemp: {
          ...state.bodyProductSearchTemp,
          options: {
            ...state.bodyProductSearchTemp.options,
            favorite: payload,
          },
        },
      };
      return setFavorites;

    case CatalogTypes.SET_IS_LOADING_PRODUCT:
      const setIsLoadingProduct: ICatalogState = {
        ...state,
        isLoadingProduct: payload,
      };
      return setIsLoadingProduct;

    case CatalogTypes.SET_IS_LOADING_RELATED_PRODUCTS:
      const setIsLoadingRelatedProducts: ICatalogState = {
        ...state,
        isLoadingRelatedProducts: payload,
      };
      return setIsLoadingRelatedProducts;

    case CatalogTypes.ADD_TO_QUERY_SEARCH_ARRAY:
      const addToQuerySearchArray: ICatalogState = {
        ...state,
        querysearchArray: !state.querysearchArray.includes(payload)
          ? [...state.querysearchArray, payload]
          : state.querysearchArray,
      };
      return addToQuerySearchArray;

    case CatalogTypes.RESET_QUERY_SEARCH_ARRAY:
      const resetQuerySearchArray: ICatalogState = {
        ...state,
        querysearchArray: [],
      };
      return resetQuerySearchArray;

    case CatalogTypes.REMOVE_FROM_QUERY_SEARCH_ARRAY:
      const removeFromQuerySearchArray: ICatalogState = {
        ...state,
        querysearchArray: state.querysearchArray.filter(
          (item: TQuerySearchArray) => item !== payload
        ),
      };
      return removeFromQuerySearchArray;

    case CatalogTypes.SET_BODY_SEARCH_FILTER:
      const setBodySearchFilter: ICatalogState = {
        ...state,
        querysearchArray: payload.querySearchArray,
      };
      return setBodySearchFilter;

    case CatalogTypes.ADD_LIKE_TO_PRODUCT:
      const newObj = {
        favorite_id: payload.id,
        user_id: payload.user_id,
      };
      const addLikeToProduct: ICatalogState = {
        ...state,
        productItem: {
          ...state.productItem,
          favorite: [...state.productItem.favorite, newObj],
        },
      };
      return addLikeToProduct;

    case CatalogTypes.REMOVE_LIKE_TO_PRODUCT:
      const removeLikeToProduct: ICatalogState = {
        ...state,
        productItem: {
          ...state.productItem,
          favorite: state.productItem.favorite.filter(
            (item: any) => item.user_id !== payload
          ),
        },
      };
      return removeLikeToProduct;

    case CatalogTypes.RESET_CATALOG_ITEMS:
      const resetCatalogItems: ICatalogState = {
        ...state,
        catalogItems: {
          count: 0,
          data: [],
          active_seasons: 0,
          published_catalogs: 0,
          total: 0,
        },
      };
      return resetCatalogItems;

    case CatalogTypes.SET_SORT_BY_HAVE_IMAGES:
      const setSortByHaveImages: ICatalogState = {
        ...state,
        bodyProductSearch: {
          ...state.bodyProductSearch,
          options: {
            ...state.bodyProductSearch.options,
            with_images: payload,
          },
        },
      };
      return setSortByHaveImages;

    case CatalogTypes.SET_PRODUCT_ATTRIBUTES:
      const setProductAttributes: ICatalogState = {
        ...state,
        productAttributes: payload,
      };
      return setProductAttributes;

    default:
      return state;
  }
}
