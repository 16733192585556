import {
  IgetCatalogReportsBySeasonAPIResponseItem,
  IgetCatalogReportsByUploadAPIItem,
  IgetMappingKeysAPIResponseItem,
  IgetMyMappingsAPIResponseItem,
} from 'api/endpoints/catalogUploads/catalogUploads.response.interface';
import { IInitialCatalogCsvUploadState } from 'interfaces/catalogCsvUpload.interface';

const initialcatalogCsvUploadState: IInitialCatalogCsvUploadState = {
  // Reports by upload
  getReportsByUpload: () => {},
  reportsByUpload: [] as IgetCatalogReportsByUploadAPIItem[],
  isLoadingByUpload: false,
  // Reports by season
  getReportsBySeason: () => {},
  reportsBySeason: [] as IgetCatalogReportsBySeasonAPIResponseItem[],
  isLoadingBySeason: false,
  // Publish upload
  publishUpload: () => {},
  isLoadingPublishUpload: {
    isLoading: false,
    uploadId: '',
  },
  // Reject upload
  rejectUpload: () => {},
  isLoadingRejectUpload: {
    isLoading: false,
    uploadId: '',
  },
  // Season
  setStartSeasonDate: () => {},
  setEndSeasonDate: () => {},
  enableSeason: () => {},
  isLoadingEnableSeason: {
    isLoading: false,
    seasonId: '',
  },
  // Upload CSV
  csvSelected: {
    papa: {} as Papa.ParseResult<unknown>,
    file: {} as any,
  },
  setCSVSelected: () => {},
  isLoadingCSVSelected: false,
  setIsLoadingCSVSelected: () => {},
  saveCatalogCSVFile: () => Promise.resolve(false),
  isLoadingSaveCatalogCSVFile: false,
  // CSV mappings
  csvMappings: [] as IgetMyMappingsAPIResponseItem[],
  csvMappingKeys: [] as IgetMappingKeysAPIResponseItem[],
  getMappings: () => {},
  isLoadingCSVMappings: false,
  deleteMapping: () => {},
  createMapping: () => {},
};

export default initialcatalogCsvUploadState;
