/* eslint-disable react-hooks/exhaustive-deps */
import { useReducer, useMemo, useEffect, useCallback, memo } from 'react';
import UserContext from './UserContext';
import initialUserState from './initialUserState';
import userReducer from 'contexts/user/reducer/user.reducer';
import * as action from 'contexts/user/reducer/user.actions';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import {
  ICompanyUpdateFields,
  IselectCompanySuperAdminProps,
  IselectUserAndCompanySuperAdminProps,
  IupdateSocialMediaProps,
  TLanguage,
  TUpdateUserPick,
} from 'interfaces/user.interface';
import { ChildrenProps } from 'interfaces/general.interface';
import useContextUILanguages from 'hooks/contexts/useContextUILanguages';
import useContextGeneral from 'hooks/contexts/useContextGeneral';

function UserProvider(props: ChildrenProps) {
  const [userState, dispatch] = useReducer(userReducer, initialUserState);
  const { user, isAuthenticated, isLoading, getAccessTokenSilently, logout } = useAuth0();
  const { t: translate } = useTranslation();
  const { languages } = useContextUILanguages();
  const { exchanges } = useContextGeneral();

  useEffect(() => {
    if (isLoading && !isAuthenticated) return;
    getAccessTokenSilently().then(async (token: string) => {
      if (!token || !user?.email_verified) return;
      return await action.loginUserAction({
        dispatch,
        token,
        emailVerified: user.email_verified,
        translate,
        logout,
      });
    });
  }, [isLoading, isAuthenticated, user?.email_verified]);

  const updateUser = useCallback(
    async ({ user }: { user: TUpdateUserPick }) => {
      const token = await getAccessTokenSilently();
      if (userState.dbUser.id === '') return false;
      return await action.updateUserAction({
        dispatch,
        dbUser: userState.dbUser,
        data: user,
        token,
        translate,
      });
    },
    [userState.dbUser, translate]
  );

  const updateCompanyData = useCallback(
    async ({ companyData }: { companyData: ICompanyUpdateFields }) => {
      const token = await getAccessTokenSilently();
      if (exchanges.length === 0) return false;
      return await action.updateCompanyAction({
        dispatch,
        companyState: userState.company,
        companyData,
        exchanges,
        token,
        translate,
      });
    },
    [userState.company, translate, exchanges]
  );

  const updateUserAvatar = useCallback(
    async (image: File) => {
      if (!userState.dbUser.id) return false;
      const token = await getAccessTokenSilently();
      return await action.updateUserAvatarAction({
        dispatch,
        userId: userState.dbUser.id,
        image,
        token,
        translate,
      });
    },
    [userState.dbUser.id, translate]
  );

  const updateCompanyLogo = useCallback(
    async (image: File) => {
      if (!userState.company.id) return false;
      const token = await getAccessTokenSilently();
      return await action.updateCompanyLogoAction({
        dispatch,
        tenantId: userState.company.id,
        image,
        token,
        translate,
      });
    },
    [userState.company.id, translate]
  );

  const updateLanguage = useCallback(
    async ({ language }: { language: TLanguage }) => {
      if (!userState.dbUser.id) return false;
      const token = await getAccessTokenSilently();
      return await action.updateLanguageAction({
        dispatch,
        dbUser: userState.dbUser,
        language,
        token,
        translate,
      });
    },
    [translate, userState.dbUser]
  );

  const updateSocialMedia = useCallback(
    async ({ socialMedia }: IupdateSocialMediaProps) => {
      if (!userState.company.id) return false;
      const token = await getAccessTokenSilently();
      return await action.updateSocialMediaAction({
        dispatch,
        companyState: userState.company,
        socialMedia,
        token,
        translate,
      });
    },
    [translate, userState.company]
  );

  const removeSocialMedia = useCallback(
    async ({ socialMediaId }: { socialMediaId: string }) => {
      if (!userState.company.id) return false;
      const token = await getAccessTokenSilently();
      return await action.removeSocialMediaAction({
        dispatch,
        companyState: userState.company,
        socialMediaId,
        token,
        translate,
      });
    },
    [translate, userState.company]
  );

  const selectUserAndCompanySuperAdmin = useCallback(
    async ({ tenant, user }: IselectUserAndCompanySuperAdminProps) => {
      if (languages.length === 0) return false;
      const token = await getAccessTokenSilently();
      return await action.selectUserAndCompanySuperAdminAction({
        dispatch,
        tenant,
        user,
        languages,
        translate,
        token,
      });
    },
    [languages, translate]
  );

  const selectCompanySuperAdmin = useCallback(
    async ({ tenant }: IselectCompanySuperAdminProps) => {
      const token = await getAccessTokenSilently();
      return await action.selectCompanySuperAdminAction({
        dispatch,
        tenant,
        translate,
        token,
      });
    },
    [translate]
  );

  const memoProvider = useMemo(
    () => ({
      ...userState,
      updateUser,
      updateCompanyData,
      updateUserAvatar,
      updateCompanyLogo,
      updateLanguage,
      updateSocialMedia,
      removeSocialMedia,
      selectUserAndCompanySuperAdmin,
      selectCompanySuperAdmin,
    }),
    [
      userState,
      updateUser,
      updateCompanyData,
      updateUserAvatar,
      updateCompanyLogo,
      updateLanguage,
      updateSocialMedia,
      removeSocialMedia,
      selectUserAndCompanySuperAdmin,
      selectCompanySuperAdmin,
    ]
  );

  return (
    <UserContext.Provider value={memoProvider}>{props.children}</UserContext.Provider>
  );
}

export default memo(UserProvider);
