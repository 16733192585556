import { useReducer, useMemo, memo, useEffect } from 'react';
import GeneralContext from './GeneralContext';
import initialGeneralState from './initialGeneralState';
import generalReducer from 'contexts/general/reducer/general.reducer';
import * as action from 'contexts/general/reducer/general.actions';
import { useTranslation } from 'react-i18next';
import { ChildrenProps } from 'interfaces/general.interface';
import { useAuth0 } from '@auth0/auth0-react';

function GeneralProvider(props: ChildrenProps) {
  const [general, dispatch] = useReducer(generalReducer, initialGeneralState);
  const { t: translate } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    (async () => {
      const token = await getAccessTokenSilently();
      return await action.getExchangesAction({ dispatch, translate, token });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      const token = await getAccessTokenSilently();
      return await action.getCountriesAction({ dispatch, translate, token });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      const token = await getAccessTokenSilently();
      return await action.getAssociationStatesAction({ dispatch, translate, token });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const memoProvider = useMemo(
    () => ({
      ...general,
    }),
    [general]
  );

  return (
    <GeneralContext.Provider value={memoProvider}>
      {props.children}
    </GeneralContext.Provider>
  );
}

export default memo(GeneralProvider);
