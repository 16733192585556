import * as metaInterface from 'interfaces/meta.interface';
import {
  IMapField,
  IMapFieldTemplates,
  IShopifyQueryItem,
  IShopifyTemplateItem,
} from 'interfaces/superAdmin.interface';

const initialMetaState: metaInterface.IInitialMetaState = {
  getConnections: () => Promise.resolve(false),
  isLoadingConnections: false,
  // Mappings
  mappings: [] as metaInterface.IMap[],
  getMaps: () => Promise.resolve(false),
  isLoadingMaps: false,
  mapSelected: null,
  setMapSelected: () => {},
  createMap: () => Promise.resolve(null),
  isLoadingCreateMap: false,
  deleteMap: () => Promise.resolve(false),
  isLoadingDeleteMap: {
    loading: false,
    mapId: '',
  },
  updateMap: () => Promise.resolve(false),
  isLoadingUpdateMap: false,
  addMapToConnection: () => Promise.resolve(false),
  isLoadingAddMapToConnection: false,
  deleteMapFromConnection: () => Promise.resolve(false),
  isLoadingDeleteMapFromConnection: false,
  addMapFieldsToMap: () => Promise.resolve(false),
  isLoadingAddMapFieldsToMap: false,
  // Map fields
  getMapFields: () => Promise.resolve(false),
  isLoadingMapFields: false,
  mapFields: [] as IMapField[],
  createMapField: () => Promise.resolve(false),
  isLoadingCreateMapField: false,
  updateMapField: () => Promise.resolve(false),
  isLoadingUpdateMapField: false,
  deleteMapField: () => Promise.resolve(false),
  isLoadingDeleteMapField: {
    loading: false,
    mapFieldId: '',
  },
  deleteMapFields: () => Promise.resolve(false),
  isLoadingDeleteMapFields: false,
  removeMapFieldFromMap: () => Promise.resolve(false),
  removeMapFieldsFromMap: () => Promise.resolve(false),
  // Map fields templates
  getMapFieldsTemplates: () => Promise.resolve(false),
  isLoadingMapFieldsTemplates: false,
  mapFieldsTemplates: [] as Array<IMapFieldTemplates>,
  deleteMapFieldTemplate: () => Promise.resolve(false),
  isLoadingDeleteMapFieldTemplate: {
    loading: false,
    templateName: '',
  },
  copyMapFieldsTemplate: () => Promise.resolve(false),
  sftp: {
    connections: [],
    createSftpConnection: () => Promise.resolve(false),
    isLoadingCreateSftpConnection: false,
    removeSftpConnection: () => {},
    isLoadingRemoveSftpConnection: {
      loading: false,
      user_name: '',
    },
  },
  shopify: {
    // Connections
    connections: [] as metaInterface.TShopifyConnectionItem[],
    connectionSelected: {} as metaInterface.TShopifyConnectionItem,
    createShopifyConnection: () => Promise.resolve(false),
    isLoadingCreateShopifyConnection: false,
    updateShopifyConnection: () => Promise.resolve(false),
    isLoadingUpdateShopifyConnection: false,
    removeShopifyConnection: () => Promise.resolve(false),
    isLoadingRemoveShopifyConnection: {
      loading: false,
      connection_id: '',
    },
    // Queries
    getShopifyQueries: () => Promise.resolve(false),
    isLoadingQueries: false,
    queries: [] as IShopifyQueryItem[],
    selectShopifyQuery: () => {},
    querySelected: {} as IShopifyQueryItem,
    createShopifyQuery: () => Promise.resolve(false),
    isLoadingCreateQuery: false,
    deleteShopifyQuery: () => Promise.resolve(false),
    isLoadingDeleteQuery: {
      loading: false,
      queryId: '',
    },
    updateShopifyQuery: () => Promise.resolve(false),
    isLoadingUpdateQuery: false,
    // Templates
    templates: [] as Array<IShopifyTemplateItem>,
    getShopifyTemplates: () => Promise.resolve(false),
    isLoadingTemplates: false,
    selectShopifyTemplate: () => {},
    templateSelected: {} as IShopifyTemplateItem,
    createUpdateShopifyTemplate: () => Promise.resolve(false),
    isLoadingCreateUpdateTemplate: false,
    deleteShopifyTemplate: () => Promise.resolve(false),
    isLoadingDeleteTemplate: {
      loading: false,
      templateTag: '',
    },
    // Configuration
    configuration: [] as metaInterface.IShopyfyConfigurationItem[],
    getShopifyConfiguration: () => Promise.resolve(false),
    isLoadingConfiguration: false,
    copyShopifyQueriesTemplate: () => Promise.resolve(false),
    isLoadingCreateUpdateConfiguration: false,
    addShopifyQueriesToConfiguration: () => Promise.resolve(false),
    isLoadingAddShopifyQueriesToConfiguration: false,
  },
};

export default initialMetaState;
