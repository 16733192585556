import { useContext } from 'react';
import GeneralContext from 'contexts/general/GeneralContext';

export default function useContextGeneral() {
  const context = useContext(GeneralContext);

  if (context === undefined) {
    throw new Error('useContextGeneral must be used within a GeneralProvider');
  }

  return context;
}
