export const SET_IS_LOADING_CONNECTIONS = 'SET_IS_LOADING_CONNECTIONS';
// MAP
export const SET_MAP_SELECTED = 'SET_MAP_SELECTED';
export const SET_IS_LOADING_MAPS = 'SET_IS_LOADING_MAPS';
export const SET_IS_LOADING_CREATE_MAP = 'SET_IS_LOADING_CREATE_MAP';
export const SET_IS_LOADING_UPDATE_MAP = 'SET_IS_LOADING_UPDATE_MAP';
export const SET_IS_LOADING_ADD_MAP_TO_CONNECTION = 'SET_IS_LOADING_ADD_MAP_TO_CONNECTION';
export const SET_IS_LOADING_DELETE_MAP_FROM_CONNECTION = 'SET_IS_LOADING_DELETE_MAP_FROM_CONNECTION';
export const DELETE_MAP = 'DELETE_MAP';
export const SET_IS_LOADING_DELETE_MAP = 'SET_IS_LOADING_DELETE_MAP';
// Map fields
export const GET_MAP_FIELDS = 'GET_MAP_FIELDS';
export const IS_LOADING_GET_MAP_FIELDS = 'IS_LOADING_GET_MAP_FIELDS';
export const CREATE_MAP_FIELD = 'CREATE_MAP_FIELD';
export const IS_LOADING_CREATE_MAP_FIELD = 'IS_LOADING_CREATE_MAP_FIELD';
export const UPDATE_MAP_FIELD = 'UPDATE_MAP_FIELD';
export const IS_LOADING_UPDATE_MAP_FIELD = 'IS_LOADING_UPDATE_MAP_FIELD';
export const DELETE_MAP_FIELD = 'DELETE_MAP_FIELD';
export const IS_LOADING_DELETE_MAP_FIELD = 'IS_LOADING_DELETE_MAP_FIELD';
export const DELETE_MAP_FIELDS = 'DELETE_MAP_FIELDS';
export const IS_LOADING_DELETE_MAP_FIELDS = 'IS_LOADING_DELETE_MAP_FIELDS';
export const ADD_FIELD_TO_MAP = 'ADD_FIELD_TO_MAP';
export const ADD_FIELDS_TO_MAP = 'ADD_FIELDS_TO_MAP';
export const REMOVE_FIELD_FROM_MAP = 'REMOVE_FIELD_FROM_MAP';
export const REMOVE_FIELDS_FROM_MAP = 'REMOVE_FIELDS_FROM_MAP';
// Map fields templates
export const GET_MAP_FIELDS_TEMPLATES = 'GET_MAP_FIELDS_TEMPLATES';
export const IS_LOADING_GET_MAP_FIELDS_TEMPLATES = 'IS_LOADING_GET_MAP_FIELDS_TEMPLATES';
export const DELETE_MAP_FIELD_TEMPLATE = 'DELETE_MAP_FIELD_TEMPLATE';
export const IS_LOADING_DELETE_MAP_FIELD_TEMPLATE =
  'IS_LOADING_DELETE_MAP_FIELD_TEMPLATE';
// SFTP
export const SET_SFTP_CONNECTIONS = 'SET_SFTP_CONNECTIONS';
export const DELETE_SFTP_CONNECTION = 'DELETE_SFTP_CONNECTION';
export const SET_IS_LOADING_DELETE_SFPT_CONNECTIONS = 'SET_IS_LOADING_DELETE_SFPT_CONNECTIONS';
export const ADD_NEW_CONNECTION = 'ADD_NEW_CONNECTION';
export const SET_IS_LOADING_CREATE_SFPT_CONNECTIONS = 'SET_IS_LOADING_CREATE_SFPT_CONNECTIONS';
// SHOPIFY
// Maps
export const GET_MAPS = 'GET_MAPS';
export const CREATE_MAP = 'CREATE_MAP';
export const UPDATE_MAP = 'UPDATE_MAP';
// Connections
export const SET_SHOPIFY_CONNECTIONS = 'SET_SHOPIFY_CONNECTIONS';
export const DELETE_SHOPIFY_CONNECTION = 'DELETE_SHOPIFY_CONNECTION';
export const SET_IS_LOADING_DELETE_SHOPIFY_CONNECTION = 'SET_IS_LOADING_DELETE_SHOPIFY_CONNECTION';
export const ADD_NEW_SHOPIFY_CONNECTION = 'ADD_NEW_SHOPIFY_CONNECTION';
export const SET_IS_LOADING_CREATE_SHOPIFY_CONNECTIONS = 'SET_IS_LOADING_CREATE_SHOPIFY_CONNECTIONS';
export const UPDATE_SHOPIFY_CONNECTION = 'UPDATE_SHOPIFY_CONNECTION';
export const SET_IS_LOADING_UPDATE_SHOPIFY_CONNECTIONS = 'SET_IS_LOADING_UPDATE_SHOPIFY_CONNECTIONS';
// Configuration
export const SET_CONFIGURATION = 'SET_CONFIGURATION';
export const SET_IS_LOADING_CONFIGURATION = 'SET_IS_LOADING_CONFIGURATION';
export const CREATE_UPDATE_CONFIGURATION = 'CREATE_UPDATE_CONFIGURATION';
export const SET_IS_LOADING_CREATE_UPDATE_CONFIGURATION = 'SET_IS_LOADING_CREATE_UPDATE_CONFIGURATION';
// Shopify queries
export const SET_QUERIES = 'SET_QUERIES';
export const SET_IS_LOADING_QUERIES = 'SET_IS_LOADING_QUERIES';
export const SET_QUERY_SELECTED = 'SET_QUERY_SELECTED';
export const ADD_NEW_QUERY = 'ADD_NEW_QUERY';
export const SET_IS_LOADING_CREATE_QUERY = 'SET_IS_LOADING_CREATE_QUERY';
export const DELETE_QUERY = 'DELETE_QUERY';
export const SET_IS_LOADING_DELETE_QUERY = 'SET_IS_LOADING_DELETE_QUERY';
export const UPDATE_QUERY = 'UPDATE_QUERY';
export const SET_IS_LOADING_UPDATE_QUERY = 'SET_IS_LOADING_UPDATE_QUERY';
// Shopify templates
export const SET_TEMPLATES = 'SET_TEMPLATES';
export const SET_IS_LOADING_TEMPLATES = 'SET_IS_LOADING_TEMPLATES';
export const SET_TEMPLATE_SELECTED = 'SET_TEMPLATE_SELECTED';
export const CREATE_UPDATE_TEMPLATE = 'CREATE_UPDATE_TEMPLATE';
export const SET_IS_LOADING_CREATE_UPDATE_TEMPLATE =
  'SET_IS_LOADING_CREATE_UPDATE_TEMPLATE';
export const DELETE_TEMPLATE = 'DELETE_TEMPLATE';
export const SET_IS_LOADING_DELETE_TEMPLATE = 'SET_IS_LOADING_DELETE_TEMPLATE';
