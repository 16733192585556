import { IgetCatalogDownloadAPIResponse } from 'api/endpoints/catalogDownload/catalogDownload.response.interface';
import { getBasePath } from '../../utils/config';
import { IBodyProductDownloadSearch } from 'interfaces/catalogDownload.interface';

export const getCatalogDownloadAPI = async (
  body: IBodyProductDownloadSearch,
  token: string,
  signal: AbortSignal | undefined
): Promise<IgetCatalogDownloadAPIResponse> => {
  const params = {
    signal: signal,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(`${getBasePath()}/product/brand/download_product`, params);
  const data = await response.json();
  return { response, data };
};
