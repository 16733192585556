import { TCompanyAPIResponse } from 'api/endpoints/company/company.response.interface';
import { IDbUserState, IInitialUserState } from 'interfaces/user.interface';

const initialUserState: IInitialUserState = {
  dbUser: {} as IDbUserState,
  company: {} as TCompanyAPIResponse,
  isLoading: true,
  updateUser: () => Promise.resolve(false),
  updateUserAvatar: () => Promise.resolve(false),
  updateCompanyLogo: () => Promise.resolve(false),
  updateCompanyData: () => Promise.resolve(false),
  updateLanguage: () => Promise.resolve(false),
  updateSocialMedia: () => Promise.resolve(false),
  removeSocialMedia: () => Promise.resolve(false),
  selectUserAndCompanySuperAdmin: () => Promise.resolve(false),
  selectCompanySuperAdmin: () => Promise.resolve(false),
};

export default initialUserState;
