import { IfindMultimediaAPIItemResponse } from 'api/endpoints/multimedia/multimedia.response.interface';
import {
  IBodyMultimediaFetch,
  IInitialMultimediaState,
} from 'interfaces/multimedia.interface';
import {
  bannerType,
  imageType,
  videoType,
} from 'views/pages/main/multimediaPage/components/constants';

export const mediaDefaultLimit = 15;

const bodyMultimediaImagesFetch: IBodyMultimediaFetch = {
  options: {
    profile: false,
    brand_id: [],
    multimedia_type: [imageType],
    search: [],
    limit: mediaDefaultLimit,
    index: 0,
  },
  filter: {
    tag: [],
    product: [],
    division: [],
    gender: [],
    country: [],
    season: [],
  },
};
const bodyMultimediaBannersFetch: IBodyMultimediaFetch = {
  options: {
    profile: false,
    brand_id: [],
    multimedia_type: [bannerType],
    search: [],
    limit: mediaDefaultLimit,
    index: 0,
  },
  filter: {
    tag: [],
    product: [],
    division: [],
    gender: [],
    country: [],
    season: [],
  },
};
const bodyMultimediaVideosFetch: IBodyMultimediaFetch = {
  options: {
    profile: false,
    brand_id: [],
    multimedia_type: [videoType],
    search: [],
    limit: mediaDefaultLimit,
    index: 0,
  },
  filter: {
    tag: [],
    product: [],
    division: [],
    gender: [],
    country: [],
    season: [],
  },
};

const initialMultimediaState: IInitialMultimediaState = {
  image: {
    items: [] as IfindMultimediaAPIItemResponse[],
    itemSelected: {} as IfindMultimediaAPIItemResponse,
    bodyMultimediaFetch: bodyMultimediaImagesFetch,
    isLoading: true,
    isLoadingMoreMedia: false,
    thereAreMoreMedia: true,
    search: {
      media: [],
      query: '',
    },
  },
  video: {
    items: [] as IfindMultimediaAPIItemResponse[],
    itemSelected: {} as IfindMultimediaAPIItemResponse,
    bodyMultimediaFetch: bodyMultimediaVideosFetch,
    isLoading: true,
    isLoadingMoreMedia: false,
    thereAreMoreMedia: true,
    search: {
      media: [],
      query: '',
    },
  },
  banner: {
    items: [] as IfindMultimediaAPIItemResponse[],
    itemSelected: {} as IfindMultimediaAPIItemResponse,
    bodyMultimediaFetch: bodyMultimediaBannersFetch,
    isLoading: true,
    isLoadingMoreMedia: false,
    thereAreMoreMedia: true,
    search: {
      media: [],
      query: '',
    },
  },
  getMedia: () => {},
  getMoreMedia: () => {},
  itemSelected: {} as IfindMultimediaAPIItemResponse,
  setItemSelected: () => {},
  removeMultimedia: () => Promise.resolve(false),
  isOpenEditModal: false,
  setIsOpenEditModal: () => {},
  searchMedia: () => {},
  createMultimedia: () => Promise.resolve(false),
  updateMultimedia: () => Promise.resolve(false),
  isLoadingCreateMultimedia: false,
};

export default initialMultimediaState;
