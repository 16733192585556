import { IGenericType } from 'interfaces/generics.interface';
import { getBasePath } from '../../utils/config';
import {
  IgetAssociationsStatesAPIResponse,
  IGetCountryDataAPIResponse,
  IGetExchangesAPIResponse,
  IgetLanguagesAPIResponse,
  IgetProductAttributesAPIResponse,
  IPingAPIResponse,
} from 'api/endpoints/general/general.response.interface';

export const pingAPI = async ({
  token,
}: {
  token: string;
}): Promise<IPingAPIResponse> => {
  const params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${getBasePath()}/status/ping`, params);
  const data = await response.json();
  return { response, data };
};

export const getLanguagesAPI = async ({
  token,
}: {
  token: string;
}): Promise<IgetLanguagesAPIResponse> => {
  const params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${getBasePath()}/user/get_languages`, params);
  const data = await response.json();
  return { response, data };
};

export const getAssociationsStatesAPI = async ({
  token,
}: {
  token: string;
}): Promise<IgetAssociationsStatesAPIResponse> => {
  const params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${getBasePath()}/client_profile/get_association_states`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const getProductAttributesAPI = async ({
  brandId,
  attribute,
  token,
}: {
  brandId: string;
  attribute: IGenericType;
  token: string;
}): Promise<IgetProductAttributesAPIResponse> => {
  const params = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      brand_id: brandId,
      attribute: attribute,
    }),
  };
  const response = await fetch(
    `${getBasePath()}/product_management/get_product_attribute`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const getExchangesAPI = async ({
  token,
}: {
  token: string;
}): Promise<IGetExchangesAPIResponse> => {
  const params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${getBasePath()}/point_of_sale_profile/get_exchange_list`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const getCountriesAPI = async ({
  token,
}: {
  token: string;
}): Promise<IGetCountryDataAPIResponse> => {
  const params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${getBasePath()}/country/get_all`, params);
  const data = await response.json();
  return { response, data };
};
