import {
  getAssociationsStatesAPI,
  getCountriesAPI,
  getExchangesAPI,
} from 'api/endpoints/general/general.api';
import * as GeneralTypes from './general.types';
import {
  IAssociationsStates,
  ITranslate,
  TAssociation,
} from 'interfaces/general.interface';
import * as status from 'interfaces/general.interface';
import { messageError } from 'views/components/UI/message';
import { Dispatch } from 'react';

export async function getExchangesAction({
  dispatch,
  translate,
  token,
}: {
  dispatch: Dispatch<any>;
  translate: ITranslate;
  token: string;
}) {
  try {
    const exchangesFetch = await getExchangesAPI({ token });
    if (exchangesFetch.response.status === 200) {
      return dispatch({
        type: GeneralTypes.SET_EXCHANGES,
        payload: exchangesFetch.data,
      });
    } else {
      console.log('error', 'Error al obtener los tipos de monedas.');
      // messageError('Error al obtener los tipos de monedas.')
      // return notificationErr(clientsError.get_exchanges_002, translate)
    }
  } catch (err) {
    console.log('error', err);
    // messageError('Error al obtener los tipos de monedas.')
    // return notificationErr(clientsError.get_exchanges_001, translate)
  }
}

export async function getCountriesAction({
  dispatch,
  translate,
  token,
}: {
  dispatch: Dispatch<any>;
  translate: Function;
  token: string;
}) {
  try {
    const getCountriesFetch = await getCountriesAPI({ token });
    if (getCountriesFetch.response.status === 200) {
      dispatch({
        type: GeneralTypes.SET_COUNTRIES,
        payload: getCountriesFetch.data,
      });
    }
  } catch (error) {
    messageError('Error al obtener los paises.');
    console.log(error);
  }
}

export async function getAssociationStatesAction({
  dispatch,
  translate,
  token,
}: {
  dispatch: Dispatch<any>;
  translate: Function;
  token: string;
}) {
  let stateValues: IAssociationsStates = {
    pending: {} as TAssociation,
    connected: {} as TAssociation,
    rejected: {} as TAssociation,
    disconnected: {} as TAssociation,
  };

  try {
    const [associationStatesFetch] = await Promise.all([
      getAssociationsStatesAPI({ token }),
    ]);
    if (
      !associationStatesFetch?.response ||
      associationStatesFetch?.response?.status !== 200 ||
      !associationStatesFetch.data ||
      associationStatesFetch?.data?.length === 0
    ) {
    } else {
      for (const state of associationStatesFetch.data) {
        if (state.state === status.pending) {
          stateValues.pending = state;
        }
        if (state.state === status.connected) {
          stateValues.connected = state;
        }
        if (state.state === status.rejected) {
          stateValues.rejected = state;
        }
        if (state.state === status.disconnected) {
          stateValues.disconnected = state;
        }
      }
      dispatch({ type: GeneralTypes.SET_ASSOCIATIONS_STATES, payload: stateValues });
    }
  } catch (err) {
    console.log('error', err);
    messageError('Error al obtener los estados de las asociaciones.');
  }
}
