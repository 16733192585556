import { IBodyProductSearch } from 'interfaces/catalog.interface';
import { getBasePath } from '../../utils/config';
import {
  IgetCatalogMetadataAPIResponse,
  IgetCatalogResponse,
  IgetFiltersAPIResponse,
  TgetProductAPIResponse,
  TUpdateProductBody,
} from 'api/endpoints/catalog/catalog.response.interface';

export const getCatalogAPI = async ({
  body,
  token,
  signal,
}: {
  body: IBodyProductSearch;
  token: string;
  signal?: AbortSignal;
}): Promise<IgetCatalogResponse> => {
  const params = {
    signal: signal,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(`${getBasePath()}/product/brand/get_product`, params);
  const data = await response.json();
  return { response, data };
};

export const getFiltersAPI = async (
  brandId: string,
  countryId: string,
  languageId: string,
  token: string
): Promise<IgetFiltersAPIResponse> => {
  const params = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      brand_id: brandId,
      country_id: countryId,
      language_id: languageId,
    }),
  };
  const response = await fetch(`${getBasePath()}/filter/get_brand_filter`, params);
  const data = await response.json();
  return { response, data };
};

export const addLikeAPI = async (
  userId: string,
  productId: string,
  token: string
): Promise<any> => {
  const params = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      user_id: userId,
      product_id: productId,
    }),
  };
  const response = await fetch(
    `${getBasePath()}/favorite/create_favorite_product`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const removeLikeAPI = async (favoriteId: string, token: string): Promise<any> => {
  const params = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${getBasePath()}/favorite/delete_favorite_product?favorite_id=${favoriteId}`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const getProductAPI = async (
  productId: string,
  brandId: string,
  languageId: string,
  userId: string,
  token: string
): Promise<TgetProductAPIResponse> => {
  const params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${getBasePath()}/product/product_profile?product_id=${productId}&brand_id=${brandId}&language_id=${languageId}&user_id=${userId}`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const updateProductAPI = async (
  body: TUpdateProductBody,
  token: string
): Promise<any> => {
  const params = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(
    `${getBasePath()}/product_management/update_product`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const getUrlToSendCSV = async (brandId: string): Promise<any> => {
  const params = {
    method: 'GET',
  };
  const response = await fetch(
    `https://dbn4v87jkd.execute-api.eu-west-1.amazonaws.com/Prod/ping`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const uploadFileAPI = async (url: string, formData: any): Promise<any> => {
  const params = {
    method: 'PUT',
    body: formData,
  };
  const response = await fetch(url, params);
  const data = await response.json();
  return { response, data };
};

export const getCatalogMetadataAPI = async (
  brandIdx: string[],
  token: string
): Promise<IgetCatalogMetadataAPIResponse> => {
  const params = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      brand_id: brandIdx,
    }),
  };
  const response = await fetch(`${getBasePath()}/product/brand/catalog_metadata`, params);
  const data = await response.json();
  return { response, data };
};
