import {
  CreateMultimediaBody,
  IBodyMultimediaFetch,
} from 'interfaces/multimedia.interface';
import { getBasePath } from '../../utils/config';
import {
  IfindMultimediaAPIResponse,
  IgetMultimediaUsagesAPIResponse,
} from 'api/endpoints/multimedia/multimedia.response.interface';

export const getMultimediaUsagesAPI = async (
  token: string
): Promise<IgetMultimediaUsagesAPIResponse> => {
  const params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${getBasePath()}/multimedia/multimedia_usage`, params);
  const data = await response.json();
  return { response, data };
};

export const findMultimediaAPI = async (
  body: IBodyMultimediaFetch,
  signal: AbortSignal | undefined,
  token: string
): Promise<IfindMultimediaAPIResponse> => {
  const params = {
    signal: signal,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(`${getBasePath()}/multimedia/find_multimedia`, params);
  const data = await response.json();
  return { response, data };
};

export const removeMultimediaAPI = async (
  imageId: string,
  token: string
): Promise<any> => {
  const params = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${getBasePath()}/multimedia/delete_multimedia?id=${imageId}`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export type TUpdateMultimediaBody = {
  id: string;
  multimedia: {
    title: string;
    description: string;
    usage_id: string | null;
    enable_download: boolean;
    start: string | Date;
    end: string | Date;
  };
  division_id: string[];
  gender_id: string[];
  product_ids: string[];
  season_ids: string[];
  tags: string[];
};
export const updateMultimediaAPI = async (
  body: TUpdateMultimediaBody,
  token: string
): Promise<any> => {
  const params = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(`${getBasePath()}/multimedia/update_multimedia`, params);
  const data = await response.json();
  return { response, data };
};

export const createMultimediaAPI = async (
  body: CreateMultimediaBody,
  token: string
): Promise<any> => {
  const params = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(`${getBasePath()}/multimedia/register_multimedia`, params);
  const data = await response.json();
  return { response, data };
};
