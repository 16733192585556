import { TResponseAPI } from 'interfaces/general.interface';
import { getBasePath } from '../../utils/config';

type TItem = { id: string; profile: boolean }[];
interface TaddToProfileAPIResponse extends TResponseAPI {
  data: { updated: number };
}
export const addMediaToProfileAPI = async (
  multimedia: TItem,
  token: string
): Promise<TaddToProfileAPIResponse> => {
  const params = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      multimedia: multimedia,
    }),
  };
  const response = await fetch(`${getBasePath()}/multimedia/add_to_profile`, params);
  const data = await response.json();
  return { response, data };
};

export const addProductsToProfileAPI = async (
  product: TItem,
  token: string
): Promise<TaddToProfileAPIResponse> => {
  const params = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      product: product,
    }),
  };
  const response = await fetch(
    `${getBasePath()}/product_management/add_to_profile`,
    params
  );
  const data = await response.json();
  return { response, data };
};
