import * as superAdminInterface from 'api/endpoints/superAdmin/superAdmin.response.interface';
import { getBasePath, dataBasePath } from '../../utils/config';

export const getClientsAPI = async ({
  token,
  index,
  limit,
}: {
  token: string;
  index: number;
  limit: number;
}): Promise<superAdminInterface.IgetClientsAPIResponse> => {
  const params = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      scope: 'client',
      populated: true,
      country_id: '',
      search: '',
      index,
      limit,
    }),
  };
  const response = await fetch(`${getBasePath()}/superadmin/search`, params);
  const data = await response.json();
  return { response, data };
};

export const getTenantsAPI = async ({
  token,
  index,
  limit,
}: {
  token: string;
  index: number;
  limit: number;
}): Promise<superAdminInterface.IgetTenantsAPIResponse> => {
  const params = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      scope: 'tenant',
      populated: true,
      country_id: '',
      search: '',
      index,
      limit,
    }),
  };
  const response = await fetch(`${getBasePath()}/superadmin/search`, params);
  const data = await response.json();
  return { response, data };
};

export const searchClientsAPI = async (query: string, token: string): Promise<any> => {
  const params = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      scope: 'tenant',
      populated: true,
      country_id: '',
      search: query,
      index: 0,
      limit: 5000,
    }),
  };
  const response = await fetch(`${getBasePath()}/superadmin/search`, params);
  const data = await response.json();
  return { response, data };
};

// Map fields
export const getMapFieldsAPI = async ({
  token,
  brandId,
}: {
  token: string;
  brandId?: string;
}): Promise<superAdminInterface.TgetMapFieldsAPIResponse> => {
  const params = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      filter: !brandId ? {} : { brand_id: brandId },
      ids: false,
    }),
  };
  const response = await fetch(`${dataBasePath}/mapping/get_map_fields`, params);
  const data = await response.json();
  return { response, data };
};

export const createMapFieldAPI = async ({
  mapField,
  token,
}: superAdminInterface.TcreateMapFieldAPIProps): Promise<superAdminInterface.TcreateMapFieldAPIResponse> => {
  const params = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      items: [mapField],
    }),
  };
  const response = await fetch(`${dataBasePath}/mapping/create_map_field`, params);
  const data = await response.json();
  return { response, data };
};

export const updateMapFieldAPI = async ({
  body,
  token,
}: {
  body: superAdminInterface.IupdateMapFieldBodyProps;
  token: string;
}): Promise<superAdminInterface.TupdateMapFieldAPIResponse> => {
  const params = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(`${dataBasePath}/mapping/update_map_field`, params);
  const data = await response.json();
  return { response, data };
};

export const deleteMapFieldAPI = async ({
  mapFieldId,
  token,
}: {
  mapFieldId: string;
  token: string;
}): Promise<any> => {
  const params = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${dataBasePath}/mapping/delete_map_field?id=${mapFieldId}`,
    params
  );
  const data = await response.json();
  return { response, data };
};

// Map fields templates
export const getMapFieldsTemplatesAPI = async ({
  token,
  brandId,
}: {
  token: string;
  brandId?: string;
}): Promise<superAdminInterface.IgetMapFieldsTemplatesAPI> => {
  const params = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(brandId ? { brand_id: brandId } : {}),
  };
  const response = await fetch(`${dataBasePath}/mapping/templates`, params);
  const data = await response.json();
  return { response, data };
};

export const createMapFieldTemplateAPI = async ({
  tag,
  map_field_ids,
  token,
}: {
  tag: string;
  map_field_ids: string[];
  token: string;
}): Promise<superAdminInterface.TcreateMapFieldTemplateAPIResponse> => {
  const params = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      tag,
      map_field_ids,
    }),
  };
  const response = await fetch(
    `${dataBasePath}/mapping/create_map_field_template`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const deleteMapFieldTemplateAPI = async ({
  templateName,
  token,
  brandId,
}: {
  templateName: string;
  token: string;
  brandId?: string;
}): Promise<any> => {
  const params = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    !brandId
      ? `${dataBasePath}/mapping/delete_template?tag=${templateName}`
      : `${dataBasePath}/mapping/delete_template?tag=${templateName}&brand_id=${brandId}`,
    params
  );
  const data = await response.json();
  return { response, data };
};

// Queries Shopify
export const getShopifyQueriesAPI = async ({
  brandId,
  token,
}: {
  brandId?: string;
  token: string;
}): Promise<superAdminInterface.IGetShopifyQueriesAPIResponse> => {
  const params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    !brandId
      ? `${dataBasePath}/shopify_connector/get_queries`
      : `${dataBasePath}/shopify_connector/get_queries?brand_id=${brandId}`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const createShopifyQueryAPI = async ({
  body,
  token,
}: superAdminInterface.TcreateShopifyQueryAPIProps): Promise<superAdminInterface.TcreateShopifyQueryAPIResponse> => {
  const params = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(`${dataBasePath}/shopify_connector/add_query`, params);
  const data = await response.json();
  return { response, data };
};

export const deleteShopifyQueryAPI = async ({
  queryId,
  token,
}: {
  queryId: string;
  token: string;
}): Promise<superAdminInterface.IdeleteShopifyQueryAPIResponse> => {
  const params = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${dataBasePath}/shopify_connector/delete_query?id=${queryId}`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const updateShopifyQueryAPI = async ({
  dataToUpdate,
  token,
}: superAdminInterface.IupdateShopifyQueryAPIProps): Promise<superAdminInterface.IupdateShopifyQueryAPIResponse> => {
  const params = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      filter: {
        id: dataToUpdate.queryId,
      },
      update: {
        query: dataToUpdate.query,
        tag: dataToUpdate.tag,
        description: dataToUpdate.description,
        indexable: dataToUpdate.indexable,
        scope: dataToUpdate.scope,
        audience: dataToUpdate.audience,
      },
    }),
  };
  const response = await fetch(`${dataBasePath}/shopify_connector/update_query`, params);
  const data = await response.json();
  return { response, data };
};

export const testShopifyQueryAPI = async ({
  connector_id,
  query_id,
  token,
}: {
  connector_id: string;
  query_id: string;
  token: string;
}): Promise<superAdminInterface.ItestShopifyQueryAPIResponse> => {
  const params = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      connector_id,
      query_id,
      limit: 3,
    }),
  };
  const response = await fetch(`${dataBasePath}/shopify_connector/test_query`, params);
  const data = await response.json();
  return { response, data };
};

// Templates Shopify
export const getTemplatesAPI = async ({
  token,
  brandId,
}: {
  token: string;
  brandId?: string;
}): Promise<superAdminInterface.IgetTemplatesAPIResponse> => {
  const params = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(
      !brandId
        ? { default: true }
        : {
            brand_id: brandId,
            default: false,
          }
    ),
  };
  const response = await fetch(`${dataBasePath}/shopify_connector/templates`, params);
  const data = await response.json();
  return { response, data };
};

export const createShopifyTemplateAPI = async ({
  body,
  token,
}: superAdminInterface.TcreateShopifyTemplateAPIProps): Promise<superAdminInterface.TcreateShopifyTemplateAPIResponse> => {
  const params = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(
    `${dataBasePath}/shopify_connector/add_template_query`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const deleteShopifyTemplateAPI = async ({
  templateTag,
  brandId,
  token,
}: {
  templateTag: string;
  brandId?: string;
  token: string;
}): Promise<superAdminInterface.TdeleteShopifyTemplateAPIResponse> => {
  const params = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    !brandId
      ? `${dataBasePath}/shopify_connector/delete_template?tag=${templateTag}`
      : `${dataBasePath}/shopify_connector/delete_template?tag=${templateTag}&brand_id=${brandId}`,
    params
  );
  const data = await response.json();
  return { response, data };
};
